import PageTitle from "../../components/common/page-title";

import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Hub } from "aws-amplify";
import { SubmissionAlert } from "../../components/common/submission-alert";
import { AuthLayout } from "../../components/auth/auth-layout";
import {
  Button,
  Checkbox,
  Text,
  ShowPassword,
} from "../../components/auth/inputs";
import { VerifyConfirmationCode } from "../../components/auth/verify-confirmation-code";
import { errorSignUpMessages } from "../../error/message";
import { updateCurrentUser } from "../../store/actions";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const infoParam = queryParams.get("info");
  const user = useSelector((state) => state.auth.currentUser);

  const onAuthEvent = (payload) => {
    if (payload.event === "autoSignIn") {
      dispatch(updateCurrentUser(payload.data));
      let path = "/";
      if (location.state && location.state.pathname)
        path = location.state.pathname;

      navigate(path, { replace: true });
    }
  };

  Hub.listen("auth", (data) => {
    const { payload } = data;
    onAuthEvent(payload);
  });

  async function signUp(formValues) {
    try {
      await Auth.signUp({
        username: formValues.email,
        password: formValues.signupPassword,
        attributes: {
          email: formValues.email,
          name: formValues.firstName + " " + formValues.lastName,
          phone_number: formValues.phoneNumber
        },
        autoSignIn: {
          enabled: true,
        },
      });
      setReq((prevState) => ({
        ...prevState,
        user: true,
        isPending: false,
      }));
    } catch (error) {
      if (error.message === errorSignUpMessages.USER_ALREADY_EXISTS) {
        setReq((prevState) => ({
          ...prevState,
          error: error.message + " Please sign in to your account.",
          isPending: false,
        }));
      } else {
        setReq((prevState) => ({
          ...prevState,
          error: error.message,
          isPending: false,
        }));
      }
    }
  }

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    signupPassword: "",
    phoneNumber: "",
    showPassword: false,
  });

  const [req, setReq] = useState({
    user: false,
    isPending: false,
    error: null,
  });

  useEffect(() => {
    document.title =
      "Sign In and Start Organizing Your Life | mybanyanlife.com";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Your first step in organizing your important documents and making them available 24/7 at any time, from anywhere."
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Your first step in organizing your important documents and making them available 24/7 at any time, from anywhere.";
      document.head.appendChild(meta);
    }
  }, []);

  const onChangeHandler = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleShowPassword = () => {
    setFormValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setReq((prevState) => ({
      ...prevState,
      isPending: true,
    }));
    signUp(formValues);
  };

  useEffect(() => {
    if (user) navigate("/");
  }, []);

  return (
    <>
      <PageTitle title="My Banyan" />

      {req.user ? (
        <VerifyConfirmationCode
          username={formValues.email}
          phoneNumber={formValues.phoneNumber}
          info={infoParam}
        />
      ) : (
        <AuthLayout
          title={"Create your My Banyan Life Account"}
          bgImg={"signup"}
        >
          {req.error && <SubmissionAlert type={"error"} message={req.error} />}
          <form className="space-y-6" onSubmit={onSubmitHandler}>
            <div className="space-y-1">
              <Text
                name="firstName"
                type="text"
                value={formValues.firstName}
                onChange={onChangeHandler}
                required
                label="First name"
              />
            </div>
            <div className="space-y-1">
              <Text
                name="lastName"
                type="text"
                value={formValues.lastName}
                onChange={onChangeHandler}
                required
                label="Last name"
              />
            </div>
            <div className="space-y-1">
              <Text
                name="email"
                type="text"
                value={formValues.email}
                onChange={onChangeHandler}
                required
                label="Email"
              />
            </div>
            <div className="space-y-1">
              <Text
                name="phoneNumber"
                type="tel"
                value={formValues.phoneNumber}
                onChange={onChangeHandler}
                required
                label="Phone number"
              />
            </div>

            <div className="relative space-y-1">
              <Text
                name="signupPassword"
                type={formValues.showPassword ? "text" : "password"}
                value={formValues.signupPassword}
                onChange={onChangeHandler}
                autoComplete="new-password"
                required
                label="Create a password"
              />
              <ShowPassword
                handleShowPassword={handleShowPassword}
                showPassword={formValues.showPassword}
              />
            </div>

            <Checkbox id="remember-me" name="remember-me" required={true}>
              Send me emails about new arrivals, hot items, daily savings and
              more.
            </Checkbox>

            <p className="text-xs text-black">
              By clicking Create Account, you acknowledge you have read and
              agree to our{" "}
              <Link
                to="/privacy-policy-and-terms-of-service"
                target="_blank"
                className="font-light text-black hover:text-gray-700 underline"
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy-policy-and-terms-of-service"
                target="_blank"
                className="font-light text-black hover:text-gray-700 underline"
              >
                Privacy Policy.
              </Link>
              Message and data rates may apply. View our{" "}
              <Link
                to="/privacy-policy-and-terms-of-service"
                target="_blank"
                className="font-light text-black hover:text-gray-700 underline"
              >
                Mobile Alerts Terms
              </Link>
            </p>

            <div>
              <Button type="submit" isPending={req.isPending}>
                Continue
              </Button>
            </div>
          </form>
        </AuthLayout>
      )}
    </>
  );
};

export default SignUp;
