export const AuthLayout = ({ title, state, children, bgImg }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 bg-none sm:px-6 lg:px-20 xl:px-24 lg:bg-none lg:bg-white bg-[url('https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/mobile-screen-2.jpeg')]">
        <div className="mx-auto w-full max-w-sm lg:w-full">
          <div>
            <div className="flex flex-col">
              <div className="mb-3">
                <img
                  className="mx-auto h-16 w-auto"
                  src="https://mybanyan-image.s3.us-west-1.amazonaws.com/Banyan-Life-horiz-logo-GRN-3-copy.png"
                  alt="Workflow"
                />
              </div>
            </div>

            <h2 className="mt-6 text-xl font-bold text-primary-green text-center">
              {title}
            </h2>
          </div>

          <div className="mt-8 flex-grow">
            <div className="mt-6">{children}</div>
          </div>
        </div>
      </div>

      <footer className="bg-white py-4 px-16 border-t border-gray-200">
        <div className="text-sm font-semibold container mx-auto flex justify-between items-center text-black">
          <p>© 2025 My Banyan, Inc.</p>
          <div className="flex space-x-4">
            <a href="/feedback-to-support" className="text-black hover:text-gray-900">
              Give feedback
            </a>
            <a href="/legal-docs/privacy" className="text-black hover:text-gray-900">
              Privacy Policy
            </a>
            <a href="/legal-docs/terms-of-service" className="text-black hover:text-gray-900">
              Terms of Use
            </a>
            <a href="/legal-docs/privacy" className="text-black hover:text-gray-900">
              Your Privacy Choices
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
