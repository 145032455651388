import { restPost } from "../../store/api";
import PageTitle from "../../components/common/page-title";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Search() {
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const branchesPerPage = 1;
  const query = searchParams.get("q");

  const indexOfLastBranch = currentPage * branchesPerPage;
  const indexOfFirstBranch = indexOfLastBranch - branchesPerPage;
  const currentBranches = Object.entries(searchResults).slice(
    indexOfFirstBranch,
    indexOfLastBranch
  );
  const totalPages = Math.ceil(
    Object.entries(searchResults).length / branchesPerPage
  );

  const handleTopicClick = (branchPath, subBranchPath, topicPath) => {
    const fullPath = `/${branchPath}/${subBranchPath}/${topicPath}`;
    navigate(fullPath);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      await restPost(
        "/search",
        {
          query: query || "",
        },
        (data) => {
          if (isMounted) {
            const groupedResults = data.data.reduce((acc, item) => {
              if (!acc[item.branchName]) {
                acc[item.branchName] = {
                  branchPath: item.branchPath,
                  subBranches: {},
                };
              }
              if (!acc[item.branchName].subBranches[item.subBranchName]) {
                acc[item.branchName].subBranches[item.subBranchName] = {
                  subBranchPath: item.subBranchPath,
                  topics: [],
                };
              }
              acc[item.branchName].subBranches[item.subBranchName].topics.push(
                item.topic
              );
              return acc;
            }, {});
            setSearchResults(groupedResults);
          }
        },
        (error) => {
          if (isMounted) {
            console.log(error);
          }
        }
      );
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [query]);

  return (
    <>
      <PageTitle title="Results" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {currentBranches.map(([branchName, branchData], branchIndex) => (
          <div key={branchIndex} className="py-8">
            <h2
              className="text-xl font-bold uppercase"
              style={{ color: "#6c8952" }}
            >
              {branchName}
            </h2>

            {Object.entries(branchData.subBranches).map(
              ([subBranchName, subBranchData], subBranchIndex) => (
                <div key={subBranchIndex}>
                  <div className="mt-6">
                    <div className="flex items-center">
                      <h3 className="text-lg font-bold text-black whitespace-nowrap capitalize">
                        {subBranchName}
                      </h3>
                      <hr className="flex-grow ml-4 border-gray-300" />
                    </div>
                  </div>

                  <div className="mt-4 grid grid-cols-2 gap-4">
                    {subBranchData.topics.map((topic, topicIndex) => (
                      <div
                        key={topicIndex}
                        onClick={() =>
                          handleTopicClick(
                            branchData.branchPath,
                            subBranchData.subBranchPath,
                            topic.path
                          )
                        }
                        className="cursor-pointer hover:opacity-75 transition-opacity"
                      >
                        <p className="text-sm text-blue-600">
                          {topic.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        ))}

        {totalPages > 1 && (
          <div className="flex justify-center items-center space-x-4 py-6">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-3 py-1 rounded-md bg-primary-green text-white disabled:opacity-50 disabled:cursor-not-allowed"
            >
              &lt;
            </button>

            <div className="flex space-x-2">
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  className={`w-8 h-8 rounded-full ${
                    currentPage === index + 1
                      ? "bg-primary-green text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="px-3 py-1 rounded-md bg-primary-green text-white disabled:opacity-50 disabled:cursor-not-allowed"
            >
              &gt;
            </button>
          </div>
        )}
      </div>
    </>
  );
}
