import PageTitle from "../../components/common/page-title";
import { SubmissionAlert } from "../../components/common/submission-alert";
import { AuthLayout } from "../../components/auth/auth-layout";
import { Button, Text } from "../../components/auth/inputs";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { Toastr } from "../../components/common/toastr";
import { products } from "../../components/my-profile/products";
import { restGet, restPost, restPut } from "../../store/api";
import { getUserDetails, updateCurrentUser } from "../../store/actions";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe("pk_live_51KwXFSEIVqRzBbsHpltTw6nujzP03gunYxpvucEToZXmUwOaDZaGcGL4BOfwp9f88aYsg42mun9ClqI6vL0NI2aY00JbPAIYcZ");

const Paynow = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [totalPrice, setTotalPrice] = useState(125);
  const [promotionCodeId, setPromotionCodeId] = useState(null);
  const [promotionCode, setPromotionCode] = useState("");
  const [canUseQuickCheckout, setCanUseQuickCheckout] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [valuePercentOff, setValuePercentOff] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const product = products.find((p) => p.id === parseInt(1));

  const [formValues, setFormValues] = useState({
    coupon: "",
    name: "",
    email: "",
    state: "",
    zip: "",
  });

  const [req, setReq] = useState({
    isPending: false,
    error: null,
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "coupon" && value.length > 5) {
      setPromotionCode(value);
      restGet(
        `/coupons?promotion=${value}&type=launching`,
        (indexData) => {
          if (indexData.length > 0) {
            Toastr({
              type: "error",
              message: "This coupon has already been redeemed",
            });
          } else {
            restGet(
              `/stripe/coupons?promotion=${value}`,
              (data) => {
                const percentOff = data.coupon.percent_off;
  
                setTotalPrice(totalPrice * (1 - percentOff / 100));
                setValuePercentOff(data.coupon.percent_off);
                setCouponId(data.coupon.id);
                setPromotionCodeId(data.id);
  
                if (!data.length) {
                  restGet(
                    `/coupons?promotion=${value}&status=false`,
                    (indexData) => {
                      if (indexData.length === 0 && value.length === 0) {
                        setTotalPrice(125);
                      } else {
                        const indexPercentOff = indexData[0]?.percentOff || 100;
  
                        setTotalPrice(totalPrice * (1 - indexPercentOff / 100));
  
                        Toastr({
                          type: "success",
                          message: "Applied promotion code",
                        });
                      }
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
                }
              },
              (error) => {
                if (error.response?.data?.error === "Promotion code not found") {
                  Toastr({
                    type: "error",
                    message: "Promotion code not found",
                  });
                }
              }
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const successUpdateCallBack = async (data) => {
    console.info("success");
  };

  const failUpdateCallback = (err) => {
    console.error(`error: ${err}`);
  };

  const couponCallBack = async (data) => {
    const body = { active: false };

    restPut(`/coupons/${promotionCodeId}`, body, successUpdateCallBack, failUpdateCallback);
  }

  const successCallBack = async (data) => {
    await restGet(
      "/users/show",
      (data) => {
        dispatch(getUserDetails(data));
        user["expiredSubscriptionDate"] = data.expiredSubscriptionDate;
        dispatch(updateCurrentUser(user));
      },
      (err) => console.error(err)
    );

    const reqBody = {
      name: formValues.name,
      email: formValues.email,
      uid: user?.uid,
    };

    restPost(
      "/email/subscription",
      reqBody,
      () => {
        console.info("Email sent successfully.");
      },
      (err) => console.error(err)
    );

    Toastr({ type: "success", message: "Payment succeeded." });
    navigate("/");
  };

  const failCallback = (err) => {
    Toastr({ type: "error", message: `Error: ${err}` });
  };

  const couponFailCallback = (err) => {
    console.error(`error: ${err}`);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setReq({ ...req, isPending: true });

    if (!stripe || !elements) {
      console.error("Stripe has not loaded yet");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: {
          line1: formValues.state,
          city: formValues.state,
          state: formValues.state,
          postal_code: formValues.zip,
        },
        email: formValues.email,
      }
    });

    if (error) {
      setReq({ isPending: false, error: error.message });
      Toastr({ type: "error", message: `Error: ${error.message}` });
      return;
    }

    const expirationDate = new Date();
    const reqBody = {
      paymentMethod: paymentMethod,
      paymentMethodId: paymentMethod.id,
      email: formValues.email,
      address: {
        line1: formValues.state,
        city: formValues.state,
        state: formValues.state,
        postal_code: formValues.zip,
      },
      amount: totalPrice,
      description: "Payment for registration manually",
      expiredTime: expirationDate.toISOString(),
      days: 365,
      planName: product.product,
      priceId: product.priceId,
      productId: product.id,
      products: products
    }

    if (promotionCode?.value) {
      const typePromotionCode = product.type;
      reqBody["typePromotionCode"] = typePromotionCode;
    }

    if (totalPrice !== 1 && promotionCode !== "" && couponId !== "") {
      await restPost("/payments/new-with-coupon", reqBody, successCallBack, failCallback)
    } else {
      await restPost("/payments/new", reqBody, successCallBack, failCallback);
    }

    if (promotionCode.code) {
      const body = {
        id: couponId,
        toEmail: user.attributes.email,
        percentOff: valuePercentOff,
        code: promotionCode.value,
        uid: user?.username,
      };

      await restPost("/coupons", body, couponCallBack, couponFailCallback)
    }

    setTimeout(() => {
      setReq({ ...req, isPending: false });
    }, 2000);
  };

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: { 
          label: "My Banyan Life", 
          amount: totalPrice * 100 
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        paymentMethods: ['googlePay', 'card', 'applePay', 'paypal'],
        paymentMethodOptions: {
          applePay: {
            merchantCountryCode: 'US',
            merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit', 'supportsEMV'],
            displayName: 'My Banyan Life',
            buttonStyle: 'black',
            paymentMethodTypes: ['card'],
            networks: ['visa', 'masterCard', 'amex', 'discover'],
            buttonType: 'buy',
            buttonLocale: 'en'
          },
          paypal: {
            buttonStyle: 'black',
            buttonType: 'checkout',
            buttonLocale: 'en',
            buttonColor: 'black',
            buttonShape: 'rect'
          }
        },
        paymentMethodConfiguration: {
          paypal: {
            enabled: true,
            buttonStyle: 'black',
            buttonType: 'checkout'
          }
        }
      });

      paymentRequest.canMakePayment().then((result) => {
        if(result) {
          setCanUseQuickCheckout(true);
          setPaymentRequest(paymentRequest);
        }
      });
      
      paymentRequest.on('paymentmethod', async (event) => {
        try {
          const expirationDate = new Date();
          const reqBody = {
            paymentMethod: event.paymentMethod,
            paymentMethodId: event.paymentMethod.id,
            email: event.payerEmail || formValues.email,
            address: event.paymentMethod.billing_details.address,
            amount: totalPrice,
            description: "Payment for registration via Google Pay",
            expiredTime: expirationDate.toISOString(),
            days: 365,
            planName: product.product,
            priceId: product.priceId,
            productId: product.id,
            products: products
          };
          
          await restPost("/payments/new", reqBody, 
            () => {
              event.complete('success');
              successCallBack();
            }, 
            (err) => {
              event.complete('fail');
              failCallback(err);
            }
          );
        } catch (error) {
          console.error("Google Pay error:", error);
          event.complete('fail');
        }
      });
    }
  }, [stripe, totalPrice]);

  return (
    <>
      <PageTitle title="My Banyan" />
      <AuthLayout title={"Enter your billing information"} bgImg={"signup"}>
        {req.error && <SubmissionAlert type={"error"} message={req.error} />}
        <form className="space-y-6" onSubmit={onSubmitHandler}>
          <div className="space-y-1">
            <h3 className="font-bold">ORDER SUMMARY</h3>
            <h3>Standard plan: <span>$125</span></h3>
            <p>If you have a coupon code, please enter it below:</p>
            <Text name="coupon" type="text" value={formValues.coupon} onChange={onChangeHandler} label={""} />
            <p>Total with coupon: ${totalPrice}</p>

            <div className="border-t border-gray-200 my-4"></div>
            {canUseQuickCheckout && paymentRequest && (
              <div>
                <h3>Quick checkout</h3>
                <div className="flex justify-center w-full">
                  <div className="w-full">
                    <PaymentRequestButtonElement
                      options={{
                        paymentRequest,
                        style: {
                          paymentRequestButton: {
                            type: 'default',
                            theme: 'dark',
                            height: '48px',
                            width: '100%'
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <p>or, use your credit card</p>
              </div>
            )}
          </div>
          <div className="space-y-1">
            <Text name="name" type="text" value={formValues.name} onChange={onChangeHandler} required label="Name" />
          </div>
          <div className="space-y-1">
            <Text name="email" type="text" value={formValues.email} onChange={onChangeHandler} required label="Email" />
          </div>
          <div className="space-y-1">
            <h3>Card Details</h3>
            <div className="border p-3 rounded">
              <CardElement />
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1 space-y-1">
              <Text name="state" type="text" value={formValues.state} onChange={onChangeHandler} required label="State" />
            </div>
            <div className="flex-1 space-y-1">
              <Text name="zip" type="text" value={formValues.zip} onChange={onChangeHandler} required label="Zip" />
            </div>
          </div>
          <div>
            <Button type="submit" isPending={req.isPending}>
              {req.isPending ? "Submitting..." : "Start My Banyan Life"}
            </Button>
          </div>
        </form>
      </AuthLayout>
    </>
  );
};

const PaynowWrapper = (props) => (
  <Elements stripe={stripePromise}>
    <Paynow {...props} />
  </Elements>
);

export default PaynowWrapper;
