import React, { useEffect, useState } from "react";
import { mainBranches } from "../private-layout/data";
import { restDelete, restGet, restPost } from "../../store/api";
import { Toastr } from "../common/toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import AWS from 'aws-sdk';
import { useSelector } from "react-redux";
import { errorSignUpMessages } from "../../error/message";

AWS.config.update({
  region: 'us-west-1', // Replace with your AWS region
  credentials: new AWS.Credentials('AKIAQGLUQCEBTIRQDN5J', '+L7gUNi7VMDCOKmsOFaAXv21MOmb4njFwBGlzX89') // Replace with your AWS credentials
});

const cognito = new AWS.CognitoIdentityServiceProvider();

const Collaborators = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [showBranchPopup, setShowBranchPopup] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [branchTopics, setBranchTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const nameSend = currentUser.attributes.name;

  const successCallBack = async (data) => {
    setName("");
    setEmail("");
    setPassword("");
    setSelectedBranch(null);
    setBranchTopics([]);
    setLoading(false);
    Toastr({ type: "success", message: "Invitation sent successfully." });
    getInviteData();
  };

  const failCallback = (err) => {
    Toastr({ type: "error", message: `Error: ${err}` });
    setLoading(false);
  };

  const getInviteData = async () => {
    setUserLoading(true);
    try {
      await restGet(
        "/invite/index",
        (data) => {
          setUsers(data);
          setUserLoading(false);
        },
        (err) => console.error(err)
      );
    } catch (error) {
      setUserLoading(false);
      console.error("Error getting invite data:", error);
    }
  };

  const handleDeleteUser = async (invitedId, receiveId) => {
    try {
      const userPoolId = 'us-west-1_HE7Pxngkh'; // Replace with your User Pool ID
      const params = {
        UserPoolId: userPoolId,
        Username: receiveId,
      };

      await cognito.adminDeleteUser(params).promise();

      await restDelete(
        "/invite/delete",
        { invitedId },
        (data) => {
          getInviteData();
        },
        (err) => console.error(err)
      );
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleUpdateInvitation = async (e) => {
    e.preventDefault();

    const selectedBranches = Object.keys(selectedTopics);
    const selectedTopicsList = selectedBranches.reduce((acc, branch) => {
      acc[branch] = Object.keys(selectedTopics[branch]).filter(
        (topic) => selectedTopics[branch][topic]
      );
      return acc;
    }, {});

    const updateInvitation = {
      email: selectedUser.receiver,
      branches: selectedBranches,
      topics: selectedTopicsList,
    }

    try {
      await restPost(
        "/invite/permission", 
        updateInvitation, 
        () => {
          Toastr({ type: "success", message: "Invitation updated successfully." });
        }, 
        () => {
          console.log("Error updating invitation");
        }
      );
    } catch (error) {
      console.error("Error updating invitation:", error);
    }
  }

  const handleSendInvitation = async (event) => {
    event.preventDefault();
    if (!name || !email || !password) {
      Toastr({
        type: "error",
        message: "Name, email, and password are required.",
      });
      return;
    }
    setLoading(true);

    const selectedBranches = Object.keys(selectedTopics);
    const selectedTopicsList = selectedBranches.reduce((acc, branch) => {
      acc[branch] = Object.keys(selectedTopics[branch]).filter(
        (topic) => selectedTopics[branch][topic]
      );
      return acc;
    }, {});

    try {
      const signUpSuccess = await signUp(email, password, name);
      if (signUpSuccess && signUpSuccess.User && signUpSuccess.User.Username) {
        const invitationData = {
          userId: signUpSuccess.User.Username,
          name,
          email,
          password,
          branches: selectedBranches,
          topics: selectedTopicsList,
        };
        await restPost("/invite/new", invitationData, successCallBack, failCallback);

        const collaborationData = {
          contactType: "Friends",
          firstName: name,
          lastName: name,
          email: email,
          innerCircle: true,
          nameSend: nameSend,
        }

        await restPost(
          "/myContacts/collaborate", 
          collaborationData, 
          () => {
            console.log("Successfully added to inner circle");
          }, 
          () => {
            console.log("Error adding to inner circle");
          });
      } else {
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error sending invitation:", error);
      setLoading(false);
    }
  };

  async function signUp(username, password, name) {
    try {
      const params = {
        "UserPoolId": "us-west-1_HE7Pxngkh", // Replace with your User Pool ID
        "Username": username,
        "TemporaryPassword": password,
        "UserAttributes": [
          { Name: "email", Value: username },
          {
            "Name": "name",
            "Value": name
          },
          {
            "Name": "email_verified",
            "Value": "true"
          }
        ]
      }

      const response = await cognito.adminCreateUser(params).promise();
      return response;
    } catch (error) {
      if (error.message === errorSignUpMessages.USER_ALREADY_EXISTS) {
        console.log(error.message);
      } else {
        console.log(error.message);
      }
      Toastr({ type: "error", message: `Sign up error: ${error.message}` });
      return false;
    }
  }

  const handleBranchClick = (subBranch) => {
    setSelectedBranch(subBranch.name);
    setBranchTopics(subBranch.topics);
    setShowBranchPopup(true);
  };

  const handleCheckboxChange = (subBranch) => {
    setSelectedTopics((prevSelectedTopics) => {
      const newSelectedTopics = { ...prevSelectedTopics };
      
      if (newSelectedTopics.hasOwnProperty(subBranch.name)) {
        delete newSelectedTopics[subBranch.name];
      } 
      else {
        newSelectedTopics[subBranch.name] = {};
      }
  
      return newSelectedTopics;
    });
  };

  const closeBranchPopup = () => {
    setShowBranchPopup(false);
  };

  const handleTopicChange = (topicName) => {
    setSelectedTopics((prevSelectedTopics) => ({
      ...prevSelectedTopics,
      [selectedBranch]: {
        ...prevSelectedTopics[selectedBranch],
        [topicName]: !prevSelectedTopics[selectedBranch]?.[topicName],
      },
    }));
  };

  const handleUserClick = (user) => {
    const updatedUser = { ...user };
    updatedUser.branches.forEach((branch) => {
      if (
        updatedUser.topics[branch] &&
        updatedUser.topics[branch].length === 0
      ) {
        const branchData = mainBranches.find((b) =>
          b.subBranches.some((sb) => sb.name === branch)
        );
        if (branchData) {
          const subBranchData = branchData.subBranches.find(
            (sb) => sb.name === branch
          );
          if (subBranchData) {
            updatedUser.topics[branch] = subBranchData.topics.map((topic) => topic.name);
          }
        }
      }
    });
  
    setSelectedUser(updatedUser);
    setShowUserPopup(true);
  };

  useEffect(() => {
    getInviteData();
  }, []);

  useEffect(() => {
    if (showUserPopup) {
      const newSelectedTopics = {};
      // Initialize branches
      selectedUser.branches.forEach(branch => {
        newSelectedTopics[branch] = {};
      });
  
      // Add topics to the corresponding branches
      Object.keys(selectedUser.topics).forEach(branch => {
        selectedUser.topics[branch].forEach(topic => {
          if (newSelectedTopics[branch]) {
            newSelectedTopics[branch][topic] = true;
          }
        });
      });
  
      setSelectedTopics(newSelectedTopics);
    }
  }, [showUserPopup, selectedUser]);

  return (
    <div className="relative mx-auto max-w-7xl px-6 lg:px-8 pt-12">
      <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
        <div className="flex-1 bg-white px-6 py-8 lg:p-12">
          <div className="flex justify-center items-center">
            <h3 className="text-center text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
              Add or delete a collaborator in your account
            </h3>
          </div>

          {/* Add user section */}
          <div className="mt-8">
            <h4 className="text-2xl text-primary-green font-semibold uppercase">
              Add a user
            </h4>

            {/* Form */}
            <form
              className="mt-4 space-y-4 pr-8"
              onSubmit={handleSendInvitation}
            >
              <div className="flex items-center space-x-4">
                <label
                  htmlFor="name"
                  className="block text-md font-bold text-primary-green w-1/5"
                >
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="mt-1 block w-4/5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-custom-bg-input"
                  required
                />
              </div>

              <div className="flex items-center space-x-4">
                <label
                  htmlFor="email"
                  className="block text-md font-bold text-primary-green w-1/5"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-4/5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-custom-bg-input"
                  required
                />
              </div>

              <div className="flex items-center space-x-4">
                <label
                  htmlFor="password"
                  className="block text-md font-bold text-primary-green w-1/5"
                >
                  Password:
                </label>
                <div className="relative w-4/5">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-custom-bg-input"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
              </div>

              {/* Access selection */}
              <p className="text-md font-bold text-primary-green mt-4">
                Choose access to one or more branches, or choose only topics
                within a branch: (click a branch to reveal the topics)
              </p>
              <div className="grid grid-cols-3 gap-4 mt-2">
                {mainBranches.map((branch) =>
                  branch.subBranches.map((subBranch) => (
                    <label
                      key={subBranch.name}
                      className="inline-flex items-center cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        className="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500"
                        onChange={() => handleCheckboxChange(subBranch)}
                      />
                      <span className="font-bold ml-2 text-primary-green">
                        <span
                          className="capitalize"
                          onClick={(e) => {
                            handleBranchClick(subBranch);
                            e.stopPropagation();
                          }}
                        >
                          {subBranch.name}
                        </span>
                      </span>
                    </label>
                  ))
                )}
              </div>

              {/* Send invitation button */}
              <div className="flex justify-end mt-4">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-full border border-transparent bg-primary-green px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  disabled={loading} // Disable button when loading
                >
                  {loading ? (
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 24c6.627 0 12-5.373 12-12h-4a8 8 0 01-8 8v4z"
                      ></path>
                    </svg>
                  ) : (
                    "Send Invitation"
                  )}
                </button>
              </div>
            </form>
          </div>

          {/* Active users section */}
          <div className="mt-12">
            <h4 className="text-2xl font-semibold text-primary-green uppercase">
              Active Users
            </h4>

            <div className="mt-4">
              <div className="mt-2 flex flex-col lg:flex-row justify-between items-center">
                {userLoading ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="loader border-4 border-t-4 border-gray-200 rounded-full w-6 h-6 animate-spin"></div>
                  </div>
                ) : (
                  <ul className="flex flex-col w-full lg:w-full">
                    {users?.map((user) => (
                      <li
                        key={user._id}
                        className="flex flex-col lg:flex-row items-center cursor-pointer mb-2"
                        onClick={() => handleUserClick(user)}
                      >
                        <div className="flex flex-col lg:flex-row items-center w-full">
                          <span className="text-primary-green hover:underline font-medium mr-4">
                            {user.nameReceiver}
                          </span>

                          <span className="text-primary-green hover:underline font-medium mr-4">
                            {user.receiver}
                          </span>

                          <span className="text-primary-green hover:underline font-medium mr-4">
                            {user.status ? <>✅ (has accepted)</> : "pending"}
                          </span>

                          <button
                            type="button"
                            className="ml-auto inline-flex items-center rounded-full border border-black bg-black px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-black focus:outline-none"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the onClick of the parent li
                              handleDeleteUser(user._id, user.receiveId);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Branch Popup */}
      {showBranchPopup && (
        <div className="fixed inset-0 z-[60] flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-bg-popup rounded-lg shadow-lg p-8 w-full max-w-3xl h-auto max-h-[90vh] overflow-y-auto">
            <h4 className="text-lg font-semibold text-primary-green">
              <span className="uppercase">{selectedBranch}</span> (select
              topics)
            </h4>
            <div className="flex flex-col mt-4 space-y-2">
              {branchTopics.map((topic) => (
                <label
                  key={topic.name}
                  className="inline-flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    className="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500"
                    checked={
                      selectedTopics[selectedBranch]?.[topic.name] || false
                    }
                    onChange={() => handleTopicChange(topic.name)}
                  />
                  <span className="ml-2 text-primary-green font-medium">
                    {topic.name}
                  </span>
                </label>
              ))}
            </div>
            <div className="flex justify-end mt-6">
              <button
                type="button"
                className="inline-flex underline items-center rounded-full border border-gray-300 bg-black px-4 py-2 text-sm text-white shadow-sm hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={closeBranchPopup}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}

      {/* User Popup */}
      {showUserPopup && selectedUser && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-bg-popup rounded-lg shadow-lg p-6 w-3/4 max-h-[90vh] relative flex flex-col">
            <div className="overflow-y-auto flex-1">
              <h4 className="text-lg font-semibold text-access-popup mb-4 sticky top-0 bg-bg-popup">
                Uncheck the boxes you want the person NOT to have access to anymore
              </h4>
              <h4 className="text-lg font-semibold text-access-popup mb-4">
                {selectedUser.nameReceiver} has access to
              </h4>
              
              <div className="grid grid-cols-2 gap-4">
                {/* Branches */}
                <div>
                  <h4 className="font-bold text-access-popup mb-2">Branches:</h4>
                  <ul className="list-disc list-inside space-y-2">
                    {selectedUser?.branches.map((branch) => (
                      <li key={branch} className="text-access-popup font-bold list-none">
                        <label className="inline-flex items-center">
                          <span className="ml-2 capitalize">{branch}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Topics */}
                <div>
                  <h4 className="font-bold text-access-popup mb-2">Topics:</h4>
                  <ul className="list-disc list-inside space-y-2">
                    {Object.keys(selectedUser.topics).map((branch) => (
                      selectedUser.topics[branch].map((topic) => (
                        <li key={topic} className="text-access-popup font-bold list-none">
                          <label className="inline-flex items-center">
                            <span className="ml-2">{topic}</span>
                          </label>
                        </li>
                      ))
                    ))}
                  </ul>
                </div>
              </div>

              <div className="text-lg font-semibold text-access-popup my-4">
                <h4>Do you want to add access?</h4>
                <h4>Click one of the branches below and see the popup</h4>
              </div>

              <h4 className="font-bold text-access-popup mb-2">Branches:</h4>
              <div className="grid grid-cols-3 gap-4 mt-2">
                {mainBranches.map((branch) =>
                  branch.subBranches.map((subBranch) => (
                    <div
                      key={subBranch.name}
                      className="inline-flex items-center"
                    >
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          className="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500"
                          checked={selectedTopics.hasOwnProperty(subBranch.name)}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleCheckboxChange(subBranch);
                          }}
                        />
                      </div>
                      <div 
                        className="font-bold ml-2 text-primary-green cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBranchClick(subBranch);
                        }}
                      >
                        <span className="capitalize">
                          {subBranch.name}
                        </span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            {/* Close */}
            <div className="mt-6 flex justify-end sticky bottom-0 bg-bg-popup pt-4">
              <button
                onClick={handleUpdateInvitation}
                className="inline-flex items-center rounded-full bg-primary-green px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
              >
                Update
              </button>
              <button
                onClick={() => setShowUserPopup(false)}
                className="inline-flex items-center rounded-full bg-black px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Collaborators;
