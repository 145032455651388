import { useState } from "react";
import RegJourneyModal from "./reg-journey-modal";

export default function Welcome({ setDialogNum }) {
    const [open, setOpen] = useState(true);

    return (
        <RegJourneyModal open={open} setOpen={setOpen}>
            <article className="h-max lg:h-[72vh] p-8 flex justify-center items-center bg-alternative-green">
                <div className="max-w-4xl w-full flex flex-row gap-x-8">
                    <div className="w-1/3 flex justify-center items-center">
                        <img
                            src="https://mybanyan-system-image.s3.us-west-1.amazonaws.com/gettings-tarted.png"
                            alt="Left side image"
                            className="h-98 w-full object-cover"
                        />
                    </div>

                    <div className="w-2/3 flex flex-col gap-y-6 lg:gap-y-8 font-custom2">
                        <p className="text-xl md:text-3xl font-bold">
                            Getting Started Made Simple
                        </p>

                        <div className="flex overflow-hidden">
                        <div className="">                            
                            <span className="w-48 md:w-60 float-right flex items-end ml-4 mt-20 bottom-corner">
                                <iframe 
                                    className="w-full h-full rounded-lg shadow-lg"
                                    src="https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/0168c7c23c32e3048975ac0e2bebd0f6/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2F0168c7c23c32e3048975ac0e2bebd0f6%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s%26height%3D600"
                                    title="Getting Started Video"
                                    frameBorder="0"
                                    allowfullscreen>
                                </iframe>
                            </span>

                            <p className="text-gray-700">
                                Thank you for joining the community of My Banyan Life. Congratulations on taking
                                this important step toward your greater peace of mind. You can protect your 
                                important records in one safe place with My Banyan Life. In addition, you can 
                                ensure that your loved ones can easily access this information when they need it.
                                We look forward to an enduring relationship with you. We are always interested 
                                in your feedback on how our product can improve. We encourage you to view our 
                                short “Getting Started” video.
                            </p>
                        </div>
                        </div>

                        <div className="flex flex-col gap-y-2 text-lg md:text-xl font-medium">
                            <div className="flex gap-x-10">
                                <div className="flex flex-col">
                                    <img
                                        src="https://mybanyan-system-image.s3.us-west-1.amazonaws.com/Brian-Harris-signature.png"
                                        alt="Brian's signature"
                                        className="h-10 md:h-10"
                                    />
                                    <p className="mt-4 font-semibold">Brian Harris, Ph.D.</p>
                                    <p className="md:text-lg">Co-founder</p>
                                </div>

                                <div className="flex flex-col">
                                    <img
                                        src="https://mybanyan-system-image.s3.us-west-1.amazonaws.com/Peter-Lewis-signature.png"
                                        alt="Peter's signature"
                                        className="h-10 md:h-10"
                                    />

                                    <p className="mt-4 font-semibold">Peter Lewis</p>
                                    <p className="md:text-lg">Co-founder</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-between items-center bg-alternative-green">
                <div className="hidden sm:block h-20 w-20" />

                <button className="flex flex-row items-center" onClick={() => setDialogNum(1)}>
                    <p>next</p>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
                        alt="Green right arrow"
                        className="h-14 sm:h-20 w-14 sm:w-20"
                    />
                </button>
            </section>
        </RegJourneyModal>
    );
}
