import { useState } from "react";
import RegJourneyModal from "./reg-journey-modal";
import { mainBranches } from "../../../components/private-layout/data";
import { restPut } from "../../../store/api";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../../store/actions";


export default function GetSelectedTopic({ setDialogNum }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [selectedBranches, setSelectedBranches] = useState([]);

    const toggleBranchSelection = (subBranch, mainBranch) => {
        setSelectedBranches((prev) => {
            if (prev.includes(subBranch.path)) {
                return prev.filter((item) => item !== subBranch.path);
            } else if (prev.length < 6) {
                return [...prev, {
                    path: mainBranch.path + "/" + subBranch.path,
                    favoriteIcon: subBranch.favoriteIcon,
                    name: subBranch.name
                }];
            } else {
                return prev;
            }
        });
    };

    const updateFavoriteBranches = (selectedBranches) => {
        let reqBody = {}
        reqBody.favorites = selectedBranches;
        restPut(
            "/users/update",
            reqBody,
            (data) => {
                dispatch(getUserDetails(data));
            },
            (err) => console.error(err)
        )
    }

    return (
        <RegJourneyModal open={open} setOpen={setOpen}>
            <article className="h-max lg:h-[72vh] p-8 flex justify-center items-center bg-white">
                <div className="max-w-4xl w-full flex flex-row gap-x-8">
                    <div className="w-1/3 flex justify-center items-center">
                        <img
                            src="https://mybanyan-system-image.s3.us-west-1.amazonaws.com/select-branches.png"
                            alt="Left side image"
                            className="h-84 w-full object-cover"
                        />
                    </div>

                    <div className="w-2/3 flex flex-col gap-y-6 lg:gap-y-8 font-custom2">
                        <p className="text-xl md:text-3xl font-bold">
                            Start with 4-6 Key Branches
                        </p>

                        <div className="flex flex-col gap-x-8">
                            <p className="mb-4">
                            Your Banyan has 15 branches (or areas) to store all your personal information. To make easy, 
                            start with 4-6 branches that best suit your life stage. Select your branches below or skip this
                            step to start with our default set.
                            </p>
                            
                            <div className="mx-auto w-full mt-8 mb-4">
                                <ul className="grid grid-cols-6 lg:grid-cols-6 2xl:grid-cols-6 gap-y-4 gap-x-8 sm:gap-x-4 md:gap-x-8 lg:gap-x-2 2xl:gap-x-10">
                                    {mainBranches.map((mainBranch) => (
                                    <>
                                        {mainBranch.subBranches
                                        .filter((subBranch) => subBranch.path !== "my-contacts")
                                        .map((subBranch, index) => (
                                            <li key={index} className="relative">
                                            <a
                                                className="block group mx-auto overflow-hidden rounded-lg"
                                                style={{ position: "relative", cursor: "pointer" }}
                                                onClick={() => toggleBranchSelection(subBranch, mainBranch)}
                                            >
                                                <img
                                                    src={subBranch.favoriteIcon}
                                                    alt={`Go to ${subBranch.image.imgAlt}`}
                                                    className="object-cover group-hover:opacity-75 transition duration-300 w-full h-auto"
                                                />
                                                <p className="mt-2 block text-3xs sm:text-sm lg:text-3xs xl:text-2xs 2xl:text-xs font-medium text-gray-900 text-center uppercase">
                                                    {`${subBranch.name.slice(0, -7)}`}
                                                </p>

                                                {selectedBranches.some(branch => branch.path.includes(subBranch.path)) && (
                                                    <div className="absolute top-0 right-0 p-1 rounded-full text-red-500 text-lg font-bold">
                                                        ✓
                                                    </div>
                                                )}

                                            </a>
                                            </li>
                                        ))}
                                    </>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-between items-center bg-white">
                <div className="hidden sm:block h-20 w-20" />
                <div>
                    [2 of 3]
                </div>

                <button className="flex flex-row items-center" onClick={() => {
                    updateFavoriteBranches(selectedBranches);
                    setDialogNum(2);
                }}>
                    <p>next</p>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
                        alt="Green right arrow"
                        className="h-14 sm:h-20 w-14 sm:w-20"
                    />
                </button>
            </section>
        </RegJourneyModal>
    );
}
