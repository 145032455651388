import { useState } from "react";
import { PaymentWrapper } from "./payment";
import { useSelector } from "react-redux";
import { memberships } from "./memberships";

const Subscription = () => {
  const currentUser = useSelector((state) => state.auth.user);
  const planName = currentUser?.currentPaymentPlan?.planName;
  const typeInterval = currentUser?.currentPaymentPlan?.typeInterval;
  const [access, setAccess] = useState(false);
  const [price, setPrice] = useState("-1");
  const [key, setKey] = useState("0");

  const discountSpecial = memberships[1].discount_special;
  const fullMember = memberships[0].full_member;

  const radioHandler = (e) => {
    setPrice(e.target.value);
    setKey(e.target.id);
  };

  return (
    <>
      {access ? (
        <PaymentWrapper value={[key, price]} />
      ) : (
        <>
          <div className="relative mx-auto max-w-7xl px-6 lg:px-8 pt-12">
            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <div className="flex justify-center items-center">
                  <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">Upgrade to Lifetime Plan</h3>
                </div>

                <div className="flex justify-between items-center mt-4 px-4">
                  <span className="text-lg font-bold text-primary-green">Current Plan:</span>
                  <span className="text-lg text-gray-900">
                    {planName === "Full membership" && typeInterval === "year" 
                      ? "Annual Plan"
                      : planName === "3-Year subscription" && typeInterval === "3 year"
                      ? "3-Year Plan"
                      : planName === "Lifetime subscription" && typeInterval === "lifetime"
                      ? "Lifetime Plan"
                      : "Annual Plan"}
                  </span>
                </div>

                <fieldset className="mt-4 pl-5">
                  <legend className="sr-only">Subscription</legend>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Left Column - Benefits */}
                    <div>
                      <h3 className="text-xl font-bold text-primary-green mb-6">Upgrade to the Lifetime Plan</h3>
                      <ul className="space-y-4">
                        <li className="flex items-start">
                          <span className="text-base text-gray-700">• No renewal fees, ever</span>
                        </li>
                        <li className="flex items-start">
                          <span className="text-base text-gray-700">• 100% credit of your current plan with upgrade</span>
                        </li>
                        <li className="flex items-start">
                          <span className="text-base text-gray-700">• Your 1st year of your current plan, will be deducted from the Lifetime Plan cost during checkout</span>
                        </li>
                      </ul>
                    </div>

                    {/* Right Column - Lifetime Subscription */}
                    <div>
                      {/* Right Column - Subscription Options */}
                      <div>
                        {planName === "Full membership" && typeInterval === "year" ? (
                          <>
                            <p className="text-xl font-bold text-primary-green mb-4">Available Plans</p>
                            <div className="space-y-3">
                            <div className="pl-3 flex items-center">
                                <input
                                  id={fullMember[1].year.key}
                                  value={fullMember[1].year.value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  Champions Plan
                                </label>
                              </div>
                              <div className="pl-3 flex items-center">
                                <input
                                  id={discountSpecial[4].key}
                                  value={discountSpecial[4].value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  3 Year Plan
                                </label>
                              </div>
                              <div className="pl-3 flex items-center">
                                <input
                                  id={discountSpecial[1].key}
                                  value={discountSpecial[1].value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  Lifetime Plan
                                </label>
                              </div>
                            </div>
                          </>
                        ) : planName === "3-Year subscription" ? (
                          <>
                            <p className="text-xl font-bold text-primary-green mb-4">Available Plans</p>
                            <div className="pl-3 flex items-center">
                              <input
                                id={discountSpecial[1].key}
                                value={discountSpecial[1].value}
                                name="notification-method"
                                type="radio"
                                onChange={radioHandler}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label className="ml-3 block text-sm font-medium text-gray-700">
                                Lifetime Plan
                              </label>
                            </div>
                          </>
                        ) : planName === "Champions Plan" ? (
                          <>
                            <p className="text-xl font-bold text-primary-green mb-4">Available Plans</p>
                            <div className="space-y-3">
                              <div className="pl-3 flex items-center">
                                <input
                                  id={discountSpecial[4].key}
                                  value={discountSpecial[4].value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  3 Year Plan
                                </label>
                              </div>
                              <div className="pl-3 flex items-center">
                                <input
                                  id={discountSpecial[1].key}
                                  value={discountSpecial[1].value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  Lifetime Plan
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="text-xl font-bold text-primary-green mb-4">Available Plans</p>
                            <div className="space-y-3">
                              <div className="pl-3 flex items-center">
                                <input
                                  id={fullMember[1].year.key}
                                  value={fullMember[1].year.value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  Champions Plan
                                </label>
                              </div>
                              <div className="pl-3 flex items-center">
                                <input
                                  id={discountSpecial[4].key}
                                  value={discountSpecial[4].value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  3 Year Plan
                                </label>
                              </div>
                              <div className="pl-3 flex items-center">
                                <input
                                  id={discountSpecial[1].key}
                                  value={discountSpecial[1].value}
                                  name="notification-method"
                                  type="radio"
                                  onChange={radioHandler}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label className="ml-3 block text-sm font-medium text-gray-700">
                                  Lifetime Plan
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/* Remove this redundant section */}
                    </div>
                  </div>
                </fieldset>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                  {/* Left Column - License Code */}
                  <div className="pl-5">
                    <h4 className="text-xl text-primary-green font-bold uppercase mb-2">IF YOU HAVE A LICENSE CODE</h4>
                    <p className="text-gray-700">Just add the promotional code during checkout once you clicked UPGRADE</p>
                  </div>

                  {/* Right Column - Upgrade Button */}
                  <div className="flex justify-end items-center">
                    <div className="rounded-md shadow">
                      <button
                        to="#"
                        disabled={Number(price) < 0}
                        onClick={() => setAccess(true)}
                        className="w-full rounded-md px-10 py-3 text-base font-medium bg-blue-800 hover:bg-gray-800 disabled:bg-white text-white disabled:text-blue-800 border-2 disabled:border-blue-800 disabled:cursor-not-allowed transition duration-300"
                      >
                        UPGRADE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Subscription;

