import { useState } from "react";
import RegJourneyModal from "./reg-journey-modal";
import { restPut } from "../../../store/api";
import { getUserDetails } from "../../../store/actions";
import { useDispatch } from "react-redux";

export default function GetStartedSimple({ setDialogNum }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);

    const updateCurrentUser = () => {
        let reqBody = {};
        reqBody.quickstart = false;

        restPut(
            "/users/update",
            reqBody,
            (data) => {
                dispatch(getUserDetails(data));
            },
            (err) => console.error(err)
        )
        
    };

    return (
        <RegJourneyModal open={open} setOpen={setOpen}>
            <article className="h-max lg:h-[72vh] p-8 flex justify-center items-center bg-alternative-green">
                <div className="max-w-4xl w-full flex flex-row gap-x-8">
                    <div className="w-1/3 flex justify-center items-center">
                        <img
                            src="https://mybanyan-system-image.s3.us-west-1.amazonaws.com/getting-started.png"
                            alt="Left side image"
                            className="h-84 w-full object-cover"
                        />
                    </div>

                    <div className="w-2/3 flex flex-col gap-y-6 lg:gap-y-8 font-custom2">
                        <p className="text-xl md:text-3xl font-bold">
                            Getting Started Made Simple
                        </p>

                        <div className="flex flex-col gap-x-8">
                            <p className="mb-4">
                            Start your journey with My Banyan Life! These tips will help you get started easily and stay organized without feeling overwhelmed.
                            </p>
                            <ol className="list-decimal list-outside pl-5 space-y-2">
                            <li>
                                <b>Explore Your Banyan: </b> Discover areas to securely store your life's important information and plans with our Guided Tour.
                            </li>
                            <li>
                                <b>Work at Your Own Pace: </b> My Banyan Life is comprehensive. Start small-work in 20-30 minute sessions to avoid feeling overwhelmed.
                            </li>
                            <li>
                                <b>Enter Simple Data First: </b> Begin with basic information, then upload files or add details later as you go.
                            </li>
                            <li>
                                <b>Enjoy the Experience: </b> Navigate easily with tutorials, articles, and checklists to guide you along the way.
                            </li>
                            <li>
                                <b>Talk to Trusted People: </b> Reach out to family, friends, or advisors for key information to add to your Banyan.
                            </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </article>

            <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-between items-center bg-alternative-green">
                <div className="hidden sm:block h-20 w-20" />
                <div>
                    [3 of 3]
                </div>

                <button className="flex flex-row items-center" onClick={() => {
                    updateCurrentUser();
                    setDialogNum(3);
                }}>
                    <p>next</p>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
                        alt="Green right arrow"
                        className="h-14 sm:h-20 w-14 sm:w-20"
                    />
                </button>
            </section>
        </RegJourneyModal>
    );
}
