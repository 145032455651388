import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

export const Text = ({ label, onChange, ...props }) => {
  const handleChange = (e) => {
    if (props.type === "tel") {
      const value = e.target.value.replace(/[^0-9()+\s-]/g, '');
      e.target.value = value;
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <>
      <label className="block text-sm font-medium text-black">{label}</label>
      <div>
        <input
          {...props}
          onChange={handleChange}
          pattern={props.type === "tel" ? "[0-9()+\s-]*" : undefined}
          inputMode={props.type === "tel" ? "tel" : undefined}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-1 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </>
  );
};

export const Checkbox = ({ children, ...props }) => {
  return (
    <div className="flex">
      <div className="flex">
        <input
          {...props}
          type="checkbox"
          className="h-4 w-4 rounded text-black"
        />
        <label
          htmlFor="remember-me"
          className="ml-2 block text-sm font-bold text-black"
        >
          {children}
        </label>
      </div>
    </div>
  );
};

export const Button = ({ children, isPending, ...props }) => {
  return (
    <button
      {...props}
      disabled={isPending}
      className="w-full flex justify-center py-2 px-4 font-medium hover:bg-indigo-500 rounded-full text-sm text-white bg-blue-700 disabled:bg-secondary-green focus:outline-none disabled:cursor-not-allowed transition duration-300"
    >
      {isPending ? (
        <>
          <div
            className="spinner-border animate-spin inline-block w-4 h-4 text-white rounded-full mr-1"
            role="status"
          />
          Processing...
        </>
      ) : (
        <p>{children}</p>
      )}
    </button>
  );
};

export const ShowPassword = ({ handleShowPassword, showPassword }) => {
  return (
    <div className="absolute inset-y-0 right-0 top-7">
      <div className="flex items-center pr-3" onClick={handleShowPassword}>
        {showPassword ? (
          <p className="text-sm underline cursor-pointer">Hide</p>
          // <EyeIcon className="h-5 w-5 text-primary-green" aria-hidden="true" />
        ) : (
          // <EyeOffIcon
          //   className="h-5 w-5 text-primary-green"
          //   aria-hidden="true"
          // />
          <p className="text-sm underline cursor-pointer">Show</p>
        )}
      </div>
    </div>
  );
};

