import React, { useState } from 'react';

const OTPInput = ({ length, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const updatedOtp = [...otp.map((d, idx) => (idx === index ? element.value : d))];
    setOtp(updatedOtp);

    onChange(updatedOtp.join(''));

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, length); // Get first 'length' characters
    if (!/^\d+$/.test(pasteData)) return; // Ensure only numbers are pasted

    const updatedOtp = pasteData.split('');
    setOtp(updatedOtp.concat(new Array(length - updatedOtp.length).fill(''))); // Fill remaining spaces
    onChange(updatedOtp.join(''));
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        if (e.target.previousSibling) {
          e.target.previousSibling.focus();
        }
      } else {
        const updatedOtp = [...otp];
        updatedOtp[index] = '';
        setOtp(updatedOtp);
        onChange(updatedOtp.join(''));
      }
    }
  };

  return (
    <div className="flex space-x-2 justify-center mb-4">
      {otp.map((data, index) => (
        <input
          key={index}
          type="text"
          name="otp"
          maxLength="1"
          className="w-10 h-12 text-center rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent text-lg font-bold"
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={(e) => e.target.select()}
          onPaste={handlePaste}
        />
      ))}
    </div>
  );
};

export default OTPInput;
