import PageTitle from "../../components/common/page-title";

import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { Button, Text } from "../../components/auth/inputs";
import { SubmissionAlert } from "../../components/common/submission-alert";
import { VerifyConfirmationCode } from "../../components/auth/verify-confirmation-code";
import { getUserDetails, updateCurrentUser } from "../../store/actions";
import { restGet, restPut } from "../../store/api";
import { errorSignInMessages } from "../../error/message";
import { TwoFactorLayout } from "../../components/auth/two-factor-layout";

const SignInChallengeName = {
    NewPasswordRequired: "NEW_PASSWORD_REQUIRED",
    SmsMfa: "SMS_MFA",
    SoftwareTokenMfa: "SOFTWARE_TOKEN_MFA",
};

export default function TwoFactor() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.currentUser);
    const userCheck = useSelector((state) => state.auth.user);

    const type = userCheck?.type;

    const [formValues, setFormValues] = useState({
        code: "",
    });

    const [req, setReq] = useState({
        user: false,
        isPending: false,
        error: null,
    });

    // Redirects from "/"" route to "/signin" route
    useEffect(() => {
        if (location.pathname === "/") navigate("/signin");
    }, []);

    // Changes the document's title and meta description
    useEffect(() => {
        document.title = "Sign In to Your Life Online | mybanyanlife.com";

        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", "Continue to organize your important documents online, because you never know what tomorrow will bring.");
        } else {
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = "Continue to organize your important documents online, because you never know what tomorrow will bring.";
            document.head.appendChild(meta);
        }
    }, []);

    const onChangeHandler = (e) => {
        setFormValues((prevState) => ({
            ...prevState,
            [e.target.code]: e.target.value,
        }));
    };

    const onSubmitHandler = async () => {
        try {
            const user = await Auth.signIn({
                username: formValues.username,
                password: formValues.password,
            });
            switch (user.challengeName) {
                case SignInChallengeName.NewPasswordRequired:
                    break;
                case SignInChallengeName.SmsMfa:
                    break;
                case SignInChallengeName.SoftwareTokenMfa:
                    dispatch(updateCurrentUser(user));
                    navigate("/two-factor", { replace: true });
                    break;
                default:
                    dispatch(updateCurrentUser(user));
                    break;
            }
        } catch (error) {
            if (error.message === errorSignInMessages.USER_NOT_COMFIRM) {
                setReq((prevState) => ({
                    ...prevState,
                    user: true,
                    isPending: false,
                }));
            } else if (error.message === errorSignInMessages.PASSWORD_ATTEMPT_EXCEED) {
                setReq((prevState) => ({
                    ...prevState,
                    error: "You have entered incorrectly 5 times, please try again in 5 minutes.",
                    isPending: false,
                }));
            } else {
                setReq((prevState) => ({
                    ...prevState,
                    error: error.message,
                    isPending: false,
                }));
            }
        }
    };

    const setShowPopup = () => {
        restPut("/users/update", { showingPopup: true }, (data) => dispatch(getUserDetails(data), (err) => console.error(err)));
    };

    useEffect(() => {
        if (userCheck) {
            let path = "/";
            if (type === "readonly") {
                path = "/sharing";
            }
            if (location.state && location.state.pathname) {
                path = location.state.pathname;
            }
            setShowPopup();
            navigate(path, { replace: true });
        }
    }, [userCheck]);

    useEffect(() => {
        if (user) {
            restGet(
                "/users/show",
                (data) => dispatch(getUserDetails(data)),
                (err) => console.error(err)
            );
        }
    }, [user]);

    return (
        <>
            <PageTitle title="Sign in to My Banyan and Start Organizing Your Life" />

            {req.user ? (
                <VerifyConfirmationCode username={formValues.username} subtitle={{ text: "go back to sign in", href: "/signin" }} />
            ) : (
                <TwoFactorLayout title={"Sign in to your account"} state={location.state ? location.state : null} bgImg={"signin"}>
                    {req.error && <SubmissionAlert type={"error"} message={req.error} />}
                    <h1 className="font-bold mb-4">Two-Factor Authentication</h1>
                    <h4 className="text-sm">We sent a verification code to your mobile number (xxx) xxx-xxxx.</h4>
                    <h4 className="text-sm">Please enter the verification code below.</h4>
                    <form
                        className="space-y-6 mt-4"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setReq((prevState) => ({
                                ...prevState,
                                isPending: true,
                            }));
                            onSubmitHandler();
                        }}
                    >
                        <div className="space-y-1">
                            <Text name="code" type="text" onChange={onChangeHandler} required label="Verification Code" />
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <p>Didn't receive the code?</p>
                                <Link to="/reset-password" className="font-semibold text-black hover:text-gray-700 underline">
                                    Resend Verification Code
                                </Link>
                            </div>
                        </div>
                        <div>
                            <Button type="submit" isPending={req.isPending}>
                                {req.isPending ? (
                                    <>
                                        <div className="spinner-border animate-spin inline-block w-4 h-4 text-white rounded-full mr-1" role="status" />
                                        Processing...
                                    </>
                                ) : (
                                    <p>Complete Verification</p>
                                )}
                            </Button>
                        </div>
                    </form>
                </TwoFactorLayout>
            )}
        </>
    );
}

