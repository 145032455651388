import { Outlet } from "react-router-dom";

import PageTitle from "../common/page-title";

export function PageLayout({ name, description, type = "" }) {
    const hasLowercase = (description) => {
        return /[a-z]/.test(description);
    };

    const startsWithNum = (description) => {
        return /[1-9][0-9]?[.]/.test(description);
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mb-8">
                <div className="mx-auto flex max-w-7xl flex-col pt-4 md:pt-6 lg:pt-8">
                    <div className={`${name === "Results" ? "bg-[#E5E9E0]" : "bg-white"} shadow sm:rounded-lg`}>
                        <div className="px-4 py-4 md:px-6">
                            <div className="flex items-center justify-between">
                                <div className="text-3xl uppercase sm:text-center text-primary-green w-full">
                                    {name}
                                </div>
                            </div>
                        </div>

                        <div className={`px-4 pt-4 pb-8 md:px-6 border-t border-gray-300 ${name === "Results" ? "bg-white" : "bg-alternative-green"} sm:rounded-b-lg`}>
                            <div className="space-y-2 sm:space-y-4">
                                {description && type === "termsOfService" && (
                                    <p className="text-gray-800 text-base font-bold">
                                        Effective Date: 08/01/2023
                                    </p>
                                )}

                                {description &&
                                    Array.isArray(description) &&
                                    description.length > 0 &&
                                    (type === "innerCircle" ? (
                                        <div className="text-gray-800 text-sm space-y-4">
                                            <p>{description[0]}</p>

                                            <ul className="ml-6 list-disc space-y-2">
                                                {description
                                                    .slice(1)
                                                    .map((el, idx) => (
                                                        <li
                                                            key={`description_${idx}`}
                                                            className={`text-gray-800 ${
                                                                hasLowercase(el)
                                                                    ? "text-sm"
                                                                    : "text-base font-bold"
                                                            } ${
                                                                startsWithNum(
                                                                    el
                                                                )
                                                                    ? "text-base font-bold"
                                                                    : "text-sm"
                                                            }`}
                                                        >
                                                            {el}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    ) : (
                                        description.map((el, idx) => (
                                            <p
                                                key={`description_${idx}`}
                                                className={`text-gray-800 ${
                                                    hasLowercase(el)
                                                        ? "text-sm"
                                                        : "text-base font-bold"
                                                } ${
                                                    startsWithNum(el)
                                                        ? "text-base font-bold"
                                                        : "text-sm"
                                                }`}
                                            >
                                                {el}
                                            </p>
                                        ))
                                    ))}

                                {description &&
                                    typeof description === "string" &&
                                    (type === "feedbackAndSupport" ? (
                                        <p className="mt-6 mx-4 2xl:mx-20 p-2 text-white md:text-lg text-center bg-primary-green">
                                            {description
                                                .split(".")
                                                .map((statement, index) => (
                                                    <span key={index}>
                                                        {index > 0 && <br />}
                                                        {statement.trim() !==
                                                            "" &&
                                                            statement.trim() +
                                                                "."}
                                                    </span>
                                                ))}
                                        </p>
                                    ) : type === "topics" ? (
                                        <p className="text-gray-800 text-sm">
                                            {description}
                                        </p>
                                    ) : (
                                        <p className="text-gray-800 text-sm">
                                            {description
                                                .split(".")
                                                .map((statement, index) => (
                                                    <span key={index}>
                                                        {index > 0 && <br />}
                                                        {statement.trim() !==
                                                            "" &&
                                                            statement.trim() +
                                                                "."}
                                                    </span>
                                                ))}
                                        </p>
                                    ))}
                            </div>

                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

