import React, { useState } from "react";
import { Auth } from "aws-amplify";
import PageTitle from "../../components/common/page-title";
import { ShowPassword, Text, Button } from "../../components/auth/inputs";
import { AuthLayout } from "../../components/auth/auth-layout";
import { SubmissionAlert } from "../../components/common/submission-alert";
import { useLocation, useNavigate } from "react-router-dom";

const subtitle = {
    text: "back to sign in",
    href: "/signin",
};

export default function ChangePassword() {
    const location = useLocation();
    const navigate = useNavigate();
    const { username, password } = location.state || {};
    const [formValues, setFormValues] = useState({
        resetUsername: "",
        password: "",
        confirmPassword: "",
        showPassword: false,
    });
    
    const [req, setReq] = useState({
        confirmed: false,
        error: null,
        isPending: false,
    });

    async function changePassword(formValues) {
        try {
            const user = await Auth.signIn(username, password);
            
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await Auth.completeNewPassword(user, formValues.password);
                setReq((prevState) => ({
                    ...prevState,
                    isPending: false,
                    confirmed: true,
                }));
                navigate("/")
            }
        } catch (error) {
            setReq((prevState) => ({
                ...prevState,
                error: error.message,
                isPending: false,
            }))
        }
    }

    const onChangeHandler = (e) => {
        setFormValues((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleShowPassword = () => {
        setFormValues((prevState) => ({
            ...prevState,
            showPassword: !prevState.showPassword,
        }));
    };

    const onSubmitHandler = () => {
        if (formValues.password !== formValues.confirmPassword) {
            setReq((prevState) => ({
                ...prevState,
                error: "Passwords do not match.",
                isPending: false,
            }));
            return;
        }
        changePassword(formValues);
    };

    return (
        <>
            <PageTitle title="My Banyan" />
            <AuthLayout title={"Change password"} subtitle={subtitle} bgImg={"forgotpassword"}>
                {req.error && <SubmissionAlert type={"error"} message={req.error} />}
                <form
                    className="space-y-6"
                    onSubmit={(e) => {
                        e.preventDefault();
                        setReq((prevState) => ({ ...prevState, isPending: true }));
                        onSubmitHandler();
                    }}
                >
                    <div className="text-sm text-white font-light">
                        <p className="text-center">Enter new password for change</p>
                    </div>
                    <div className="relative space-y-1">
                        <Text
                            name="password"
                            type={formValues.showPassword ? "text" : "password"}
                            value={formValues.password}
                            onChange={onChangeHandler}
                            required
                            autoComplete="new-password"
                            label="New Password *"
                        />
                        <ShowPassword handleShowPassword={handleShowPassword} showPassword={formValues.showPassword} />
                    </div>
                    <div className="relative space-y-1">
                        <Text
                            name="confirmPassword"
                            type={formValues.showPassword ? "text" : "password"}
                            value={formValues.confirmPassword}
                            onChange={onChangeHandler}
                            required
                            autoComplete="new-password"
                            label="Confirm new password *"
                        />
                        <ShowPassword handleShowPassword={handleShowPassword} showPassword={formValues.showPassword} />
                    </div>
                    <div>
                        <Button type="submit" isPending={req.isPending}>SUBMIT</Button>
                    </div>
                </form>
            </AuthLayout>
        </>
    );
}
