import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { confirmSignup } from "./confirm-signup";
import { Button } from "./inputs";
import { AuthLayout } from "./auth-layout";
import { SubmissionAlert } from "../common/submission-alert";
import OTPInput from "./otp-input";

const successMessage =
    "Verification code has been sent to your email. Please type or paste the code to the box below and verify your code.";

export const VerifyConfirmationCode = ({ username, phoneNumber, subtitle, info }) => {
    const location = useLocation();

    const [type, setType] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [otp, setOtp] = useState('');

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return '';
        const lastFourDigits = phoneNumber.slice(-4);
        return `(xxx) xxx-${lastFourDigits}`;
      };

    const handleOtpChange = (value) => {
        setOtp(value);
      };

    const dispatchCallback = async (type) => {
        console.log("location: ", location);
    };

    async function resendConfirmationCode() {
        try {
            await Auth.resendSignUp(username);
            setIsPending(false);
            setSuccess(true);
        } catch (err) {
            console.error("error resending code: ", err);
        }
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setIsPending(true);
        confirmSignup(username, otp, type, dispatchCallback, setIsPending);
    };

    const getStatusUser = async () => {
        try {
            await Auth.currentAuthenticatedUser();
        } catch (error) {
            console.error("error: ", error);
        }
    };

    useEffect(() => {
        if (info === "inner") {
            setType("readonly");
        } else if (info === "free") {
            setType("free");
        } else if (type === "") {
            setType("free");
        }
        getStatusUser();
    }, [info, type]);

    return (
        <>
            <AuthLayout title={"Enter verify code"} subtitle={subtitle}>
                {success && (
                    <SubmissionAlert
                        type={"success"}
                        message={successMessage}
                    />
                )}

                <form className="space-y-6 flex flex-col items-center" onSubmit={onSubmitHandler}>
                    <div className="text-sm text-black font-light">
                        <p className="text-left py-3">
                            Please enter the 6-digit verification code we sent to <span className="font-bold">{formatPhoneNumber(phoneNumber)}</span>
                        </p>
                    </div>
                    <OTPInput length={6} onChange={handleOtpChange} />
                    <div className="flex flex-row text-sm items-center text-center">
                        <p>Didn't receive it? </p>
                        <span 
                            className="underline cursor-pointer hover:text-gray-700"
                            onClick={(e) => {
                                e.preventDefault();
                                resendConfirmationCode();
                            }}
                        >Get another code</span>
                    </div>
                    <div className="w-full">
                        <Button type="submit" isPending={isPending}>
                            Sign in
                        </Button>
                    </div>
                </form>

                <div className="pt-5 text-sm text-black font-light flex justify-center text-center">
                    <p>
                        Code not working?
                        <Link
                        to="/resend-verification-code"
                        className="underline hover:text-gray-700"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsPending(true);
                            if (!isPending) resendConfirmationCode();
                        }}
                        >
                        Try another way
                        </Link>
                    </p>
                </div>
            </AuthLayout>
        </>
    );
};

