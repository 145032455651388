import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const scrollTop = (shouldScroll = true) => {
  if (shouldScroll) {
    window.scrollTo(0, 0);
  }
};

export function Slidebar({ open, setOpen, directory }) {
  const [topics, setTopics] = useState({});
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setTopics(user?.topics);
  }, []);

  const isTopicInUserTopics = (branchName, topicName) => {
    if (topics) {
      const branchTopics = topics[branchName];
      if (branchTopics) {
        return branchTopics.length === 0 || branchTopics.includes(topicName);
      }
    }
    return false;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {directory.name}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <nav
                          className="h-full overflow-y-auto"
                          aria-label="Directory"
                        >
                          {directory.subBranches.map((subBranch) => (
                            <React.Fragment key={subBranch.name}>
                              {subBranch.name.toLowerCase() ===
                              "my contacts" ? null : (
                                <div key={subBranch.name} className="relative">
                                  <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-primary-green px-6 py-4 text-sm font-medium text-white">
                                    <Link
                                      to={`${directory.path}/${subBranch.path}`}
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                      className="group flex items-center"
                                    >
                                      <img
                                        src={subBranch.icon}
                                        alt={`${subBranch.name} icon`}
                                        className="mr-4 w-6 h-6"
                                      />
                                      <h3 className="uppercase group-hover:underline">
                                        {subBranch.name}
                                      </h3>
                                      <p className="ml-2">▶</p>
                                    </Link>
                                  </div>

                                  <ul className="relative z-0 divide-y divide-gray-200">
                                    {subBranch.name.toUpperCase() ===
                                      "HEALTH BRANCH" && (
                                      <li
                                        className="bg-white"
                                        onClick={() => {
                                          setOpen(false);
                                        }}
                                      >
                                        <div className="relative flex items-center space-x-3 px-6 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 bg-[#d9e4cb] hover:opacity-80">
                                          <div className="min-w-0 flex-1">
                                            <Link to="everyday/planning-for-my-medical-emergency">
                                              <span
                                                className="absolute inset-0"
                                                aria-hidden="true"
                                              />
                                              <p className="text-sm font-medium text-primary-green flex items-center">
                                                <span className="mr-2">
                                                  {
                                                    "Planning for My Medical Emergency >"
                                                  }
                                                </span>
                                              </p>
                                            </Link>
                                          </div>
                                        </div>
                                      </li>
                                    )}

                                    {subBranch.name.toUpperCase() ===
                                      "ESTATE PLANS BRANCH" && (
                                      <li
                                        className="bg-white"
                                        onClick={() => {
                                          setOpen(false);
                                        }}
                                      >
                                        <div className="relative flex items-center space-x-3 px-6 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 bg-[#d9e4cb] hover:opacity-80">
                                          <div className="min-w-0 flex-1">
                                            <Link to="eventual/estate-planning-101">
                                              <span
                                                className="absolute inset-0"
                                                aria-hidden="true"
                                              />
                                              <p className="text-sm font-medium text-primary-green flex items-center">
                                                <span className="mr-2">
                                                  {"Estate Planning 101 >"}
                                                </span>
                                              </p>
                                            </Link>
                                          </div>
                                        </div>
                                      </li>
                                    )}

                                    {subBranch.name.toUpperCase() ===
                                      "SENIOR LIFE PLANS BRANCH" && (
                                      <>
                                        <li
                                          className="bg-white"
                                          onClick={() => {
                                            setOpen(false);
                                          }}
                                        >
                                          <div className="relative flex items-center space-x-3 px-6 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 bg-[#d9e4cb] hover:opacity-80">
                                            <div className="min-w-0 flex-1">
                                              <Link to="eventual/relocation-checklists">
                                                <span
                                                  className="absolute inset-0"
                                                  aria-hidden="true"
                                                />
                                                <p className="text-sm font-medium text-primary-green flex items-center">
                                                  <span className="mr-2">
                                                    {
                                                      "Relocation Checklists (3) >"
                                                    }
                                                  </span>
                                                </p>
                                              </Link>
                                            </div>
                                          </div>
                                        </li>

                                        <li
                                          className="bg-white"
                                          onClick={() => {
                                            setOpen(false);
                                          }}
                                        >
                                          <div className="relative flex items-center space-x-3 px-6 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 bg-[#d9e4cb] hover:opacity-80">
                                            <div className="min-w-0 flex-1">
                                              <Link to="eventual/retirement-checklist">
                                                <span
                                                  className="absolute inset-0"
                                                  aria-hidden="true"
                                                />
                                                <p className="text-sm font-medium text-primary-green flex items-center">
                                                  <span className="mr-2">
                                                    {"Retirement Checklist >"}
                                                  </span>
                                                </p>
                                              </Link>
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    )}

                                    {subBranch.topics.map((topic) => {
                                      const isDisabled = isTopicInUserTopics(subBranch.name, topic.name);
                                      return (
                                        <li
                                          key={topic.name}
                                          className={`px-6 py-3 ${
                                            isDisabled
                                              ? "bg-gray-300 cursor-not-allowed text-gray-500"
                                              : "bg-white hover:bg-gray-50"
                                          }`}
                                          onClick={() => {
                                            if (!isDisabled) {
                                              setOpen(false);
                                            }
                                          }}
                                        >
                                          <div
                                            className={`relative flex items-center space-x-3 ${
                                              isDisabled ? "" : "focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
                                            }`}
                                          >
                                            <div className="min-w-0 flex-1">
                                              {isDisabled ? (
                                                // Disabled li does not contain a clickable link
                                                <p className="text-sm font-medium text-gray-900 flex items-center">
                                                  <span className="mr-2">{topic.name}</span>
                                                </p>
                                              ) : (
                                                // Enabled li contains a clickable link
                                                <a
                                                  href={`/${directory.path}/${subBranch.path}/${topic.path}`}
                                                  onClick={scrollTop(false)}
                                                  className="focus:outline-none"
                                                >
                                                  {/* Extend touch target to entire panel */}
                                                  <span
                                                    className="absolute inset-0"
                                                    aria-hidden="true"
                                                  />
                                                  <p className="text-sm font-medium text-gray-900 flex items-center">
                                                    <span className="mr-2">{topic.name}</span>
                                                    {user &&
                                                      user?.lastVisited?.length > 1 &&
                                                      user?.lastVisited
                                                        .map((item) => item.name)
                                                        .includes(topic.name) && (
                                                        <span
                                                          className={`text-4xl ${
                                                            !user.following.length ||
                                                            !user.following.some(
                                                              (item) =>
                                                                item.mainBranch === directory.path &&
                                                                item.subBranch === subBranch.path &&
                                                                item.topicName === topic.path
                                                            )
                                                              ? "text-red-500 hidden"
                                                              : "text-red-500"
                                                          }`}
                                                        >
                                                          &#8226;
                                                        </span>
                                                      )}
                                                  </p>
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </nav>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
