import { scrollTop } from "../../../components/common/scroll-top";
import { RenderFavorites } from "../../../components/dashboard/render-favorites";
import { Link } from "react-router-dom";

export default function OldDashboard({user, data, setQuickStart, resetPriorities, type, setShowOldDashboard}) {
  return (
    <>
      <div className="grid grid-cols-2 grid-rows-1 gap-10">
        <div className="w-full bg-white flex flex-col pt-8 px-8 pb-4 rounded-[70px] 2xl:rounded-[80px]">
          <div className="w-full flex items-center justify-center gap-2">
            <h2 className="uppercase font-medium text-xl 2xl:text-2xl text-primary-green">
              Current Branches
            </h2>

            <button
              onClick={() => {
                if (type !== "readonly") {
                  resetPriorities(user.favorites);
                }
              }}
              className={`group ${
                type !== "readonly" ? "cursor-pointer" : "cursor-not-allowed"
              } flex flex-col justify-center items-center`}
            >
              <img
                src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/reset.svg"
                alt="priority branches icon"
                className={`h-8 w-8 ${
                  type !== "readonly" &&
                  "group-hover:animate-spin-slow transition duration-300"
                }`}
              />
              <p className="text-sm text-primary-green">Reset</p>
            </button>
          </div>

          <div className="flex items-center">
            <RenderFavorites user={user} setQuickStart={setQuickStart} />
          </div>
          <div className="mt-2 w-full border-t border-primary-green"></div>

          <div 
            className="flex items-center justify-center cursor-pointer mt-2"
            onClick={() => setShowOldDashboard(false)}
          >
            <svg
              className="w-6 h-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="3" y="3" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="10" y="3" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="17" y="3" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="3" y="10" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="10" y="10" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="17" y="10" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="3" y="17" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="10" y="17" width="4" height="4" rx="1" fill="#93A68D" />
              <rect x="17" y="17" width="4" height="4" rx="1" fill="#93A68D" />
            </svg>
            <span className="ml-2 text-black text-sm">SEE ALL BRANCHES</span>
          </div>
        </div>

        {data && data.lastVisited ? (
          data.lastVisited.length > 0 ? (
            // DESKTOP RECENT TOPICS
            <div className="w-full bg-white flex flex-col gap-6 pt-8 px-8 pb-4 rounded-[70px] 2xl:rounded-[80px] bg-[url('https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/greenbanyan_lessOpacity-2-01.svg')] bg-no-repeat bg-contain bg-center">
              <h2 className="mt-3 uppercase font-medium text-xl 2xl:text-2xl text-primary-green text-center">
                Recent Topics
              </h2>

              <div className="w-full">
                {data &&
                  data.lastVisited
                    .reverse()
                    .slice(0, 5)
                    .map((el, index) => (
                      <div
                        key={el.name}
                        className="w-full flex flex-col items-start gap-4 mt-4"
                      >
                        {type !== "readonly" ? (
                          <Link to={el.path} onClick={scrollTop}>
                            <p className="text-primary-green font-medium text-2xs 2xl:text-sm hover:opacity-80 hover:underline transition duration-300">
                              {el.name}
                            </p>
                          </Link>
                        ) : (
                          <p className="text-primary-green font-medium text-2xs 2xl:text-sm">
                            {el.name}
                          </p>
                        )}

                        <hr
                          className={`w-full border-1 ${
                            index + 1 !== data.lastVisited.slice(0, 5).length
                              ? "border-primary-green"
                              : "border-transparent"
                          }`}
                        />
                      </div>
                    ))}
              </div>
            </div>
          ) : (
            // DESKTOP QUICKSTART
            <div className="w-full bg-white flex flex-col gap-6 px-8 pb-8 pt-6 rounded-[70px] 2xl:rounded-[80px]">
              <div className="w-full flex items-center justify-center gap-2 mt-2 2xl:mt-4">
                <h2 className="uppercase font-medium text-xl 2xl:text-2xl text-primary-green">
                  QUICKSTART
                </h2>

                <img
                  src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/quickstart-icon-GreeN-01.svg"
                  alt="priority branches icon"
                  className="h-12 w-12 group-hover:animate-spin-slow transition duration-300"
                />
              </div>

              <p className="mt-1 text-2xs 2xl:text-sm text-primary-green">
                To get the most out of My Banyan, we recommend that you view the
                simple QuickStart Guide below.
              </p>

              <div className="flex justify-center">
                <iframe
                  className="2xl:h-52 rounded-md"
                  src="https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/d12707d8ab6fd9073600bd20d7e82192/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fd12707d8ab6fd9073600bd20d7e82192%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s%26height%3D600"
                  width="100%"
                  height="100%"
                  title={"QuickStart Guide Video"}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </div>
            </div>
          )
        ) : (
          <div className="w-full bg-white flex flex-col gap-10 p-10 rounded-[70px] 2xl:rounded-[80px]" />
        )}
      </div>
    </>
  );
}
