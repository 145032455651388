import { useNavigate } from "react-router-dom";

export const SiginLayout = ({ title, state, children, bgImg }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1 flex-row py-12 px-4 bg-primary-green bg-none sm:px-6 lg:px-20 xl:px-24 lg:bg-none lg:bg-primary-green bg-[url('https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/mobile-screen-2.jpeg')]">
        <div className="flex flex-col w-1/2 bg-primary-green text-white p-8">
          <div>
            <div className="mb-4">
              <img
                className="ml-6 h-16 w-auto"
                src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/whitebanyan_icon.svg"
                alt="Workflow"
              />
            </div>
            <div className="mb-4">
              <img
                className="h-3 w-auto"
                src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/mybanyan_whtltext.svg"
                alt="Workflow"
              />
            </div>
          </div>
          <h2 className="text-4xl font-bold my-4">My Banyan Life</h2>
          <p className="mt-4 text-sm">
            My Banyan is dedicated to protecting your data. The steps required to register
            and sign in are necessary to ensure your information will stay safe with us.
          </p>
          <p className="mt-4 text-xl font-bold">New here?</p>
          <p className="text-sm">
            <span 
              className="underline hover:underline cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </span> {" "}
             to create an account and get started.
          </p>
          <p className="mt-4 text-xl font-bold">Having trouble Signing In?</p>
          <p className="mt-4 text-sm">
            Contact <a href="mailto:support@mybanyanlife.com" className="text-white">support@mybanyanlife.com</a>
          </p>
        </div>

        <div className="mx-auto w-full max-w-sm lg:w-full">
          <div>
            <h2 className="mt-6 text-xl font-bold text-primary-green text-center">
              {title}
            </h2>
          </div>

          <div className="mt-8 flex-grow">
            <div className="mt-6 bg-white p-8 rounded-lg">{children}</div>
          </div>
        </div>
      </div>

      <footer className="bg-primary-green py-4 px-16">
        <div className="text-sm font-semibold container mx-auto flex justify-between items-center text-black">
          <p>© 2025 My Banyan, Inc.</p>
          <div className="flex space-x-4">
            <a href="/feedback-to-support" className="text-black hover:text-gray-900">
              Give feedback
            </a>
            <a href="/legal-docs/privacy" className="text-black hover:text-gray-900">
              Privacy Policy
            </a>
            <a href="/legal-docs/terms-of-service" className="text-black hover:text-gray-900">
              Terms of Use
            </a>
            <a href="/legal-docs/privacy" className="text-black hover:text-gray-900">
              Your Privacy Choices
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
