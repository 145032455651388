import { Fragment, useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Auth } from "aws-amplify";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { BellIcon, XIcon, MenuIcon } from "@heroicons/react/outline";
import moment from "moment";

import Calendar from "./calendar";
import Notification from "./notification";

import {
    sidebarNavigation,
    userNavigation,
    navigation,
    secondaryNavigation,
    mainBranches,
} from "./data";
import { Slidebar } from "./slidebar";
import { Breadcrumb } from "../breadcrumb";
import PageTitle from "../common/page-title";
import { restPut, restGet, restPost } from "../../store/api";
import { getUserDetails, updateTypeByAll } from "../../store/actions";
import { NotificationContext } from "../../contexts/notificationContext";
import { classNames } from "../../lib/classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";


const scrollTop = (shouldScroll = true) => {
    if (shouldScroll) {
        window.scrollTo(0, 0);
    }
};

export const PrivateLayout = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.currentUser);
    const currentUser = useSelector((state) => state.auth.user);
    const notification = useSelector((state) => state.notification.byAll);
    const type = useSelector((state) => state.type.byAll);
    const navigate = useNavigate();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [popupCalendar, setPopupCalendar] = useState(false);
    const [show, setShow] = useState(false);
    const [popup, setPopup] = useState(null);
    const [idx, setIdx] = useState(0);
    const [noti, setNoti] = useState([]);
    const [countNoti, setCountNoti] = useState(null);
    const [count, setCount] = useState(0);
    const [userType, setUserType] = useState(null);

    const successCallback = (data) => {};

    const failCallback = (err) => {
        console.error(err);
    };

    const signOut = async () => {
        try {
            await Auth.signOut();
            navigate("/signin", { replace: true });
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (
            currentUser &&
            (currentUser.status === true ||
                new Date(currentUser.expiredSubscriptionDate) > new Date()) &&
            currentUser.type !== "readonly" &&
            count === 0
        ) {
            const title = "Trial expiration date";
            restGet(
                "/calendarEvents/index",
                (data) => {
                    const check = data.some((x) => x.title === title);
                    if (check === false) {
                        const values = {
                            title: title,
                            allDay: true,
                            when: currentUser.expiredSubscriptionDate,
                            notes: title,
                        };
                        restPost(
                            "/todoEvents/new",
                            values,
                            successCallback,
                            failCallback
                        );
                    }
                },
                (err) => console.error(err)
            );
            setCount(1);
            const intervalId = setInterval(() => {
                const remainingTime =
                    new Date(currentUser.expiredSubscriptionDate).getTime() -
                    new Date().getTime();
                if (remainingTime <= 0) {
                    clearInterval(intervalId);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [currentUser]);

    useEffect(() => {
        restGet(
            "/calendarEvents/notification",
            (data) => {
                const filteredData = data.filter((event) => {
                    const eventDate = moment(event.when);
                    const oneMonthAhead = moment().add(1, "months");

                    return eventDate.isBefore(oneMonthAhead);
                });

                setNoti(filteredData);
                setCountNoti(filteredData.length);
            },
            (err) => console.error(err)
        );
    }, [show]);

    useEffect(() => {
        if (notification && Object.keys(notification).length > 0) {
            if (popup === true) {
                setPopup(false);
            } else {
                setPopup(true);
            }
        }
    }, [notification]);

    useEffect(() => {
        if (notification) {
            setNoti([notification]);
        }
    }, [popup]);

    useEffect(() => {
        restGet(
            "/users/show",
            (data) => {
                if (type === "readonly") {
                    setUserType("readonly");
                } else {
                    setUserType(data.type);
                }
                if (!data.paymentCustomerId) {
                    navigate("/paynow", { replace: true });
                }
            },
            (err) => console.error(err)
        );
    }, []);

    useEffect(() => {
        if (type !== null && type !== "" && currentUser) {
            let reqBody = {};
            reqBody.type = type;
            restPut(
                "/users/update",
                reqBody,
                (data) => {
                    dispatch(getUserDetails(data));
                    dispatch(updateTypeByAll(""));
                },
                (err) => console.error(err)
            );
        }
    }, [userType, type, currentUser]);

    return (
        <>
            <PageTitle title="My Banyan" />

            {currentUser &&
                (currentUser.status === false ||
                    new Date(currentUser?.expiredSubscriptionDate) <
                        new Date()) &&
                window.location.pathname !== "/my-profile" && (
                <div
                    className="fixed z-50 inset-0 overflow-y-auto"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-32 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <p
                                    className="leading-5 font-medium text-gray-900"
                                    id="modal-headline"
                                >
                                    {/* Your account has expired. You have to upgrade to membership
                account to continue using website. */}
                                    Your 21-day Free trial has expired.
                                    Click below to select the plan that is
                                    right for you and your family.
                                </p>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <Link
                                    to="/my-profile"
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-green text-base font-medium text-white hover:bg-custom-dark-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-green sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    {/* Upgrade account */}
                                    Purchase membership
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <NotificationContext.Provider value={{ noti, setNoti }}>
                <>
                    <div className="fixed top-15 right-0 z-50 w-1/4">
                        {show && noti.length > 0 && countNoti > 0 && (
                            <div className="fixed top-15 right-0 z-50 w-full lg:w-1/4 bg-gray-300">
                                <div>
                                    <div className="h-2"></div>
                                    <div className="flex mx-4 my-1 items-center">
                                        <div className="border-solid border-2 border-white bg-white text-center rounded-full flex-grow">
                                            Notification
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => setShow(false)}
                                            className="ml-2"
                                        >
                                            <XIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className="max-h-96 overflow-y-auto">
                                    {(popup !== null || show) &&
                                        noti &&
                                        noti
                                            .filter((el) => !el.read)
                                            .map((item, idx) => (
                                                <div key={item._id}>
                                                    <Notification
                                                        event={item}
                                                        idx={idx}
                                                        close={setShow}
                                                        dismissHandler={() => {
                                                            setCountNoti(
                                                                countNoti - 1
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <Slidebar
                        open={open}
                        setOpen={setOpen}
                        directory={mainBranches[idx]}
                    />
                    <div className="min-h-full relative">
                        <Transition.Root show={sidebarOpen} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed inset-0 flex z-40 lg:hidden"
                                onClose={setSidebarOpen}
                            >
                                <Transition.Child
                                    as={Fragment}
                                    enter="transition-opacity ease-linear duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity ease-linear duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                                </Transition.Child>
                                <Transition.Child
                                    as={Fragment}
                                    enter="transition ease-in-out duration-300 transform"
                                    enterFrom="-translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transition ease-in-out duration-300 transform"
                                    leaveFrom="translate-x-0"
                                    leaveTo="-translate-x-full"
                                >
                                    <div className="relative flex-1 flex flex-col max-w-xs w-full py-4 bg-primary-green">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-in-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in-out duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                                <button
                                                    type="button"
                                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                    onClick={() =>
                                                        setSidebarOpen(false)
                                                    }
                                                >
                                                    <span className="sr-only">
                                                        Close sidebar
                                                    </span>
                                                    <XIcon
                                                        className="h-6 w-6 text-white"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </Transition.Child>
                                        <div className="flex-shrink-0 flex items-center px-4">
                                            <div className="flex items-center">
                                                <img
                                                    className="h-10 w-auto"
                                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/top-navbar/mybanyan-logo-white-01.svg"
                                                    alt="Workflow"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 flex-1 flex flex-col divide-y divide-white/70 h-0 overflow-y-auto">
                                            <nav className="px-2 space-y-1">
                                                {navigation.map((item) => (
                                                    <Link
                                                        key={item.name}
                                                        to={item.href}
                                                        onClick={() => {
                                                            setSidebarOpen(
                                                                false
                                                            );
                                                        }}
                                                        className={`group uppercase flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md ${
                                                            item.current &&
                                                            userType &&
                                                            userType ===
                                                                "readonly" &&
                                                            item.name.toLowerCase() !==
                                                                "sharing history"
                                                                ? "text-gray-400 pointer-events-none"
                                                                : "text-white"
                                                        } hover:text-primary-green hover:bg-primary-bg`}
                                                    >
                                                        <img
                                                            src={item.icon}
                                                            className="mr-4 w-6 h-6"
                                                            alt="iem"
                                                        />
                                                        {item.name}
                                                    </Link>
                                                ))}
                                            </nav>

                                            <nav className="px-2 mt-6 pt-6 grow">
                                                {secondaryNavigation.map(
                                                    (item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={() => {
                                                                setSidebarOpen(
                                                                    false
                                                                );
                                                            }}
                                                            className={`group uppercase flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md ${
                                                                item.current
                                                                    ? "text-white"
                                                                    : "text-gray-400 pointer-events-none"
                                                            } hover:text-primary-green hover:bg-primary-bg`}
                                                        >
                                                            <img
                                                                src={item.icon}
                                                                className="mr-4 w-6 h-6"
                                                                alt="iem"
                                                            />
                                                            {item.name}
                                                        </Link>
                                                    )
                                                )}
                                            </nav>

                                            <div className="px-2 mt-6 pt-6">
                                                <span className="px-2 py-2 text-white font-medium text-sm">
                                                    © {new Date().getFullYear()}{" "}
                                                    MY BANYAN INC.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                                <div
                                    className="flex-shrink-0 w-14"
                                    aria-hidden="true"
                                >
                                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                                </div>
                            </Dialog>
                        </Transition.Root>

                        {/* Static sidebar for desktop */}
                        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0 shadow-xl shadow-black z-20">
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex flex-col flex-grow bg-primary-green py-4 overflow-y-hidden">
                                <div className="flex items-center flex-shrink-0 px-4">
                                    <img
                                        className="h-10 w-auto"
                                        src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/top-navbar/mybanyan-logo-white-01.svg"
                                        alt="Workflow"
                                    />
                                </div>
                                <nav
                                    className="mt-5 flex-1 flex flex-col divide-y divide-white/70 overflow-y-scroll"
                                    aria-label="Sidebar"
                                >
                                    <div className="px-2 space-y-1">
                                        <Link
                                            key={sidebarNavigation[0].name}
                                            to={sidebarNavigation[0].href}
                                            className={classNames(
                                                sidebarNavigation[0].current
                                                    ? "text-white"
                                                    : "text-gray-400 pointer-events-none",
                                                "group uppercase flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md hover:text-primary-green hover:bg-primary-bg"
                                            )}
                                            aria-current={
                                                sidebarNavigation[0].current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            <img
                                                src={sidebarNavigation[0].icon}
                                                className="mr-4 w-6 h-6"
                                                alt="iem"
                                            />
                                            {sidebarNavigation[0].name}
                                        </Link>
                                        {sidebarNavigation
                                            .slice(1, 4)
                                            .map((item, index) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIdx(index);
                                                        setOpen(true);
                                                    }}
                                                    className={classNames(
                                                        item.current &&
                                                            userType &&
                                                            userType !==
                                                                "readonly"
                                                            ? "text-white"
                                                            : "text-gray-400 pointer-events-none",
                                                        "group uppercase flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md hover:text-primary-green hover:bg-primary-bg"
                                                    )}
                                                    aria-current={
                                                        item.current
                                                            ? "page"
                                                            : undefined
                                                    }
                                                >
                                                    <img
                                                        src={item.icon}
                                                        className="mr-4 w-6 h-6"
                                                        alt="iem"
                                                    />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        <Link
                                            key={sidebarNavigation[4].name}
                                            to={sidebarNavigation[4].href}
                                            className={classNames(
                                                sidebarNavigation[4].current &&
                                                    userType &&
                                                    userType !== "readonly"
                                                    ? "text-white"
                                                    : "text-gray-400 pointer-events-none",
                                                "group uppercase flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md hover:text-primary-green hover:bg-primary-bg"
                                            )}
                                            aria-current={
                                                sidebarNavigation[4].current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            <img
                                                src={sidebarNavigation[4].icon}
                                                className="mr-4 w-6 h-6"
                                                alt="iem"
                                            />
                                            {sidebarNavigation[4].name}
                                        </Link>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            {navigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className={`group uppercase flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md ${
                                                        item.current &&
                                                        (item.name.toLowerCase() ===
                                                            "sharing history" ||
                                                            (userType &&
                                                                userType !==
                                                                    "readonly"))
                                                            ? "text-white"
                                                            : "text-gray-400 pointer-events-none"
                                                    } hover:text-primary-green hover:bg-primary-bg`}
                                                >
                                                    <img
                                                        src={item.icon}
                                                        className="mr-4 w-6 h-6"
                                                        alt="iem"
                                                    />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6 grow">
                                        <div className="px-2 space-y-1">
                                            {/* original bottom navi */}
                                            {secondaryNavigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className={`group uppercase flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white hover:text-primary-green hover:bg-primary-bg`}
                                                >
                                                    <img
                                                        src={item.icon}
                                                        className="mr-4 w-6 h-6"
                                                        alt="iem"
                                                    />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <span className="px-4 py-2 text-white font-medium text-sm">
                                            © {new Date().getFullYear()} MY
                                            BANYAN INC.
                                        </span>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="lg:pl-64 flex flex-col flex-1">
                            <div className="fixed bottom-[20%] right-0 z-[9999] rotate-270 bg-primary-green opacity-80 hover:opacity-100 -mr-12 hover:-mr-11 px-4 pt-2 pb-8 rounded-t-lg text-white transition duration-300">
                                {/* Hides Feedback tab on mobile screen during registration journey */}
                                {user.quickstart === false && (
                                    <button
                                        onClick={() => {
                                            navigate("feedback-to-support");
                                            scrollTop();
                                        }}
                                    >
                                        Feedback
                                    </button>
                                )}
                            </div>

                            {/* {currentUser && currentUser.type === "free" && (
                                <div className="sticky top-0 left-0 right-0 z-20 bg-yellow-500 text-black py-2 px-4 text-center shadow-lg flex justify-between items-center">
                                    <h1>
                                        You have {daysLeft} days left of your trial. Subscribe
                                        today with the code <strong>UPGRADE20</strong> to get 20% off if you
                                        subscribe before the trial is over.
                                    </h1>
                            </div>
                            )} */}

                            {/* Navigation */}
                            {/* <div className={`sticky ${currentUser?.type === "free" ? "top-10" : "top-0"} z-20 flex-shrink-0 lg:border-l lg:border-lime-800 flex h-16 bg-primary-green shadow-lg`}> */}
                            <div className={`sticky top-0 z-20 flex-shrink-0 lg:border-l lg:border-lime-800 flex h-16 bg-primary-green shadow-lg`}>
                                <div className="flex-1 px-4 flex justify-between">
                                    <div className="lg:hidden mr-4 h-full flex items-center">
                                        <div className="flex items-center">
                                            <img
                                                className="h-10 w-auto"
                                                src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/top-navbar/mybanyan-logo-white-01.svg"
                                                alt="Workflow"
                                            />
                                        </div>
                                    </div>
                                    {/* {/* Search bar */}
                                    <div className="flex-1 px-4 flex justify-between mt-3">
                                        <div className="lg:hidden mr-4 h-full flex items-center">
                                            <div className="flex items-center">
                                                <img
                                                    className="h-10 w-auto"
                                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/top-navbar/mybanyan-logo-white-01.svg"
                                                    alt="Workflow"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-1 flex">
                                            <div className="w-full max-w-2xl">
                                                <label htmlFor="search" className="sr-only">
                                                    Search
                                                </label>
                                                <div className="relative">
                                                    <div className="flex items-center gap-4">
                                                        <div className="text-white whitespace-nowrap">SEARCH</div>
                                                        <div className="relative w-full">
                                                            <input
                                                                type="text"
                                                                name="search"
                                                                id="search"
                                                                className="block w-full pl-4 pr-12 py-2 border border-transparent rounded-md leading-5 bg-white bg-opacity-90 text-gray-700 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
                                                                placeholder="Use one or two words to find topics you are looking for like &quot;Finance&quot; or &quot;Property Insurance&quot;"
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' && e.target.value.trim()) {
                                                                        navigate(`/search?q=${encodeURIComponent(e.target.value.trim())}`);
                                                                    }
                                                                }}
                                                            />
                                                            <div 
                                                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                                                onClick={() => {
                                                                    const searchValue = document.getElementById('search').value.trim();
                                                                    if (searchValue) {
                                                                        navigate(`/search?q=${encodeURIComponent(searchValue)}`);
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faSearch} className="h-5 w-5 text-gray-400" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-2 flex items-center space-x-4 lg:ml-6 lg:space-x-6">
                                        {/* Calendar hidden */}
                                        <div className="group flex items-center justify-center xl:hidden">
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setPopupCalendar(
                                                        !popupCalendar
                                                    );
                                                }}
                                                className="relative flex items-center justify-center text-primary-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-green"
                                            >
                                                <img
                                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/11-calendar-icon-white.svg"
                                                    alt="video tutorial icon"
                                                    className="h-10 w-10"
                                                    aria-hidden="true"
                                                />
                                                <span className="sr-only">
                                                    Add file
                                                </span>
                                            </button>
                                            <div className="hidden cursor-default group-hover:block group-hover:absolute group-hover:top-16 group-hover:-mt-1 group-hover:px-3 group-hover:py-1 group-hover:bg-gray-700 group-hover:text-white group-hover:text-sm group-hover:after:absolute group-hover:after:ml-11 group-hover:after:-top-4 group-hover:after:border-8 group-hover:after:border-b-gray-700 group-hover:after:border-x-transparent group-hover:after:border-t-transparent">
                                                <p>Calendar</p>
                                            </div>
                                        </div>

                                        <div className="group flex items-center justify-center">
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("tutorials");
                                                }}
                                                className="relative flex items-center justify-center text-primary-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-green"
                                            >
                                                <img
                                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/top-navbar/video-tutorial-icon-white.svg"
                                                    alt="video tutorial icon"
                                                    className="h-10 w-10"
                                                    aria-hidden="true"
                                                />
                                                <span className="sr-only">
                                                    Add file
                                                </span>
                                            </button>
                                            <div className="hidden cursor-default group-hover:block group-hover:absolute group-hover:top-16 group-hover:-mt-1 group-hover:px-3 group-hover:py-1 group-hover:bg-gray-700 group-hover:text-white group-hover:text-sm group-hover:after:absolute group-hover:after:ml-11 group-hover:after:-top-4 group-hover:after:border-8 group-hover:after:border-b-gray-700 group-hover:after:border-x-transparent group-hover:after:border-t-transparent">
                                                <p>Tutorials</p>
                                            </div>
                                        </div>

                                        {/* Profile dropdown */}
                                        <div className="group flex items-center justify-center">
                                            <Menu
                                                as="div"
                                                className="relative flex-shrink-0"
                                            >
                                                <div>
                                                    <Menu.Button className="bg-white rounded-full flex text-sm">
                                                        <span className="sr-only">
                                                            Open user menu
                                                        </span>
                                                        <img
                                                            className="h-8 w-8 rounded-full"
                                                            src={
                                                                user.attributes &&
                                                                user.attributes
                                                                    .profile
                                                                    ? user
                                                                          .attributes
                                                                          .profile
                                                                    : "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                                                            }
                                                            alt=""
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map(
                                                            (item) => (
                                                                <Menu.Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                    }) => (
                                                                        <Link
                                                                            to={
                                                                                item.href
                                                                            }
                                                                            className={classNames(
                                                                                active
                                                                                    ? "bg-gray-100"
                                                                                    : "",
                                                                                "block px-4 py-2 text-sm text-gray-700"
                                                                            )}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        )}

                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to={"/"}
                                                                    className={classNames(
                                                                        active
                                                                            ? "bg-gray-100"
                                                                            : "",
                                                                        "block px-4 py-2 text-sm text-gray-700"
                                                                    )}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        signOut();
                                                                    }}
                                                                >
                                                                    Sign out
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                            <div className="hidden cursor-default group-hover:block group-hover:absolute group-hover:top-16 group-hover:-mt-1 group-hover:px-3 group-hover:py-1 group-hover:bg-gray-700 group-hover:text-white group-hover:text-sm group-hover:after:absolute group-hover:after:ml-[46px] group-hover:after:-top-4 group-hover:after:border-8 group-hover:after:border-b-gray-700 group-hover:after:border-x-transparent group-hover:after:border-t-transparent">
                                                <p>Account Profile</p>
                                            </div>
                                        </div>
                                        <div className="group flex items-center justify-center">
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    if (
                                                        userType &&
                                                        userType !== "readonly"
                                                    ) {
                                                        e.preventDefault();
                                                        if (show === false) {
                                                            setShow(true);
                                                        } else {
                                                            setShow(false);
                                                        }
                                                    }
                                                }}
                                                className={`relative flex bg-white p-1 rounded-full items-center justify-center text-primary-green ${
                                                    userType &&
                                                    userType === "readonly"
                                                        ? "cursor-not-allowed"
                                                        : "cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-green"
                                                }`}
                                            >
                                                <BellIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                                {userType &&
                                                    userType !== "readonly" &&
                                                    noti &&
                                                    noti.filter(
                                                        (el) => !el.read
                                                    ).length > 0 && (
                                                        <span className="absolute bottom-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />
                                                    )}
                                            </button>
                                            <div className="hidden cursor-default group-hover:block group-hover:absolute group-hover:top-16 group-hover:lg:-ml-16 group-hover:-mt-1 group-hover:px-3 group-hover:py-1 group-hover:bg-gray-700 group-hover:text-white group-hover:text-sm group-hover:after:absolute group-hover:after:ml-9 group-hover:after:lg:ml-[68px] group-hover:after:-top-4 group-hover:after:border-8 group-hover:after:border-b-gray-700 group-hover:after:border-x-transparent group-hover:after:border-t-transparent">
                                                <p>Notifications</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="border-l border-lime-800 px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-green lg:hidden"
                                    onClick={() => {
                                        setSidebarOpen(true);
                                    }}
                                >
                                    <span className="sr-only">
                                        Open sidebar
                                    </span>
                                    <MenuIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div className={classNames("sticky z-10 top-16")}>
                                <Breadcrumb />
                            </div>

                            {/* MAIN CONTENT */}
                            <main className="flex-1 relative">
                                <div className="flex-1 flex items-stretch overflow-hidden min-h-screen xl:pr-72">
                                    <div className='pb-20 md:pb-24 lg:pb-0 px-4 md:px-8 bg-[url("https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/top-navbar/private-layout-bg.jpg")] bg-cover bg-center bg-no-repeat w-full bg-fixed'>
                                        <Outlet />
                                    </div>
                                </div>

                                <div className="hidden xl:block xl:fixed xl:inset-y-0 xl:right-0 xl:w-72">
                                    <Calendar />
                                </div>
                                {popupCalendar && (
                                    <>
                                        <div className="block fixed inset-y-0 right-0 w-72 bg-white xl:hidden">
                                            <Calendar
                                                popupCalendar={popupCalendar}
                                                setPopupCalendar={
                                                    setPopupCalendar
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </main>
                        </div>
                    </div>

                    {/* MOBILE FOOTER */}
                    <div className="lg:hidden fixed bottom-0 bg-primary-gray h-20 md:h-28 flex justify-around items-center w-full">
                        <div className="flex flex-col items-center justify-center gap-2">
                            <Link
                                key={sidebarNavigation[0].name}
                                to={sidebarNavigation[0].href}
                                className={classNames(
                                    sidebarNavigation[0].current
                                        ? "bg-primary-green text-white"
                                        : "bg-gray-600 opacity-25 text-primary-green hover:bg-primary-green hover:text-white pointer-events-none",
                                    "block p-2"
                                )}
                                aria-current={
                                    sidebarNavigation[0].current
                                        ? "page"
                                        : undefined
                                }
                            >
                                <img
                                    src={sidebarNavigation[0].icon}
                                    className="w-6 h-6 md:w-10 md:h-10"
                                    alt="iem"
                                />
                            </Link>
                            <div className="text-2xs sm:text-xs md:text-lg font-medium text-gray-500">
                                {sidebarNavigation[0].name}
                            </div>
                        </div>

                        {sidebarNavigation.slice(1, 4).map((item, index) => {
                            if (userType && userType === "readonly") {
                                return (
                                    <div
                                        key={item.name}
                                        className="flex flex-col items-center justify-center gap-2"
                                    >
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="bg-gray-600 opacity-25 text-primary-green block p-2 cursor-not-allowed"
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            <img
                                                src={item.icon}
                                                className="w-6 h-6 md:w-10 md:h-10"
                                                alt="iem"
                                            />
                                        </Link>
                                        <div className="text-2xs sm:text-xs md:text-lg font-medium text-gray-500">
                                            {item.name}
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        key={item.name}
                                        className="flex flex-col items-center justify-center gap-2"
                                    >
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIdx(index);
                                                setOpen(true);
                                            }}
                                            className={classNames(
                                                item.current
                                                    ? "bg-primary-green text-white"
                                                    : "bg-gray-600 opacity-25 text-primary-green hover:bg-primary-green hover:text-white pointer-events-none",
                                                "block p-2"
                                            )}
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            <img
                                                src={item.icon}
                                                className="w-6 h-6 md:w-10 md:h-10"
                                                alt="iem"
                                            />
                                        </Link>
                                        <div className="text-2xs sm:text-xs md:text-lg font-medium text-gray-500">
                                            {item.name}
                                        </div>
                                    </div>
                                );
                            }
                        })}

                        {userType && userType === "readonly" ? (
                            <div className="flex flex-col items-center justify-center gap-2">
                                <Link
                                    key={sidebarNavigation[4].name}
                                    to=""
                                    className="bg-gray-600 opacity-25 text-primary-green block p-2 cursor-not-allowed"
                                    aria-current={
                                        sidebarNavigation[4].current
                                            ? "page"
                                            : undefined
                                    }
                                >
                                    <img
                                        src={sidebarNavigation[4].icon}
                                        className="w-6 h-6 md:w-10 md:h-10"
                                        alt="iem"
                                    />
                                </Link>
                                <div className="text-2xs sm:text-xs md:text-lg font-medium text-gray-500">
                                    {sidebarNavigation[4].name}
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center gap-2">
                                <Link
                                    key={sidebarNavigation[4].name}
                                    to={sidebarNavigation[4].href}
                                    className={classNames(
                                        sidebarNavigation[4].current
                                            ? "bg-primary-green text-white"
                                            : "bg-gray-600 opacity-25 text-primary-green hover:bg-primary-green hover:text-white pointer-events-none",
                                        "block p-2"
                                    )}
                                    aria-current={
                                        sidebarNavigation[4].current
                                            ? "page"
                                            : undefined
                                    }
                                >
                                    <img
                                        src={sidebarNavigation[4].icon}
                                        className="w-6 h-6 md:w-10 md:h-10"
                                        alt="iem"
                                    />
                                </Link>
                                <div className="text-2xs sm:text-xs md:text-lg font-medium text-gray-500">
                                    {sidebarNavigation[4].name}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            </NotificationContext.Provider>
        </>
    );
};

