/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-1",
  aws_cognito_identity_pool_id: "eu-west-1:d587d983-6657-4671-9629-162b572c4be9",
  aws_cognito_region: "us-west-1",
  aws_user_pools_id: "us-west-1_HE7Pxngkh",
  aws_user_pools_web_client_id: "3q02q4bgssfmnabcp6p1eiusct",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "ON",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["SMS", "EMAIL"],
};

export default awsmobile;

