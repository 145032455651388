export const products = [
    // {
    //     id: 1,
    //     product: "Full membership",
    //     rate: "1",
    //     priceId: "price_1R9JKMEIVqRzBbsH0VplReWA",
    //     type: "one"
    // },
    {
        id: 1,
        product: "Full membership",
        rate: "125",
        // priceId: "price_1NQ0PMEIVqRzBbsHyGGvfHxi",
        // prod
        priceId: "price_1Pp6eMEIVqRzBbsHa0Gw1XTQ",
        type: "one",
    },
    {
        id: 2,
        product: "Full membership",
        rate: "15",
        // priceId: "price_1NPy53EIVqRzBbsHHqRkvLUZ",
        // prd
        priceId: "price_1Pp6emEIVqRzBbsH7ernLsnB",
        type: "month",
    },
    {
        id: 3,
        product: "Full membership",
        rate: "69",
        // priceId: "price_1NPy4uEIVqRzBbsHsA6xdwYl",
        // prd
        priceId: "price_1Pp6fKEIVqRzBbsHnxkysLNi",
        type: "one",
    },
    {
        id: 4,
        product: "Full membership",
        rate: "10",
        // priceId: "price_1NPy4XEIVqRzBbsHcfJNwTxJ",
        // prd
        priceId: "price_1Pp6iVEIVqRzBbsHB3DPRVZR",
        type: "month",
    },
    {
        id: 5,
        product: "5-Year subscription",
        rate: "250",
        // priceId: "price_1NPy4DEIVqRzBbsHh0fnWeCz",
        // prd
        priceId: "price_1Pp6ffEIVqRzBbsHW00SGkS1",
        type: "five",
    },
    {
        id: 6,
        product: "Lifetime subscription",
        rate: "699",
        // priceId: "price_1NPy41EIVqRzBbsHkT9zA0jg",
        // prd
        priceId: "price_1Pp6fxEIVqRzBbsHcE5mStAc",
        type: "lifetime",
    },
    {
        id: 7,
        product: "Veterans Special Discount rate per year",
        rate: "100",
        // priceId: "price_1NPy3kEIVqRzBbsHKKQUC6xV",
        // prd
        priceId: "price_1Pp6gIEIVqRzBbsHdgdKlmhE",
        type: "one",
    },
    {
        id: 8,
        product: "Veterans Special Discount rate per month",
        rate: "10",
        // priceId: "price_1NPy4XEIVqRzBbsHcfJNwTxJ",
        // prd
        priceId: "price_1Pp6iVEIVqRzBbsHB3DPRVZR",
        type: "month",
    },
    {
        id: 9,
        product: "Veterans Special Discount rate per year",
        rate: "75",
        // priceId: "price_1NPy4uEIVqRzBbsHsA6xdwYl",
        // prd
        priceId: "price_1Pp6fKEIVqRzBbsHnxkysLNi",
        type: "one",
    },
    {
        id: 10,
        product: "Veterans Special Discount rate per month",
        rate: "10",
        // priceId: "price_1NPy4XEIVqRzBbsHcfJNwTxJ",
        // prd
        priceId: "price_1Pp6iVEIVqRzBbsHB3DPRVZR",
        type: "month",
    },
    {
        id: 11,
        product: "First responders rate per year",
        rate: "100",
        // priceId: "price_1NPy3kEIVqRzBbsHKKQUC6xV",
        // prd
        priceId: "price_1Pp6gIEIVqRzBbsHdgdKlmhE",
        type: "one",
    },
    {
        id: 12,
        product: "First responders rate per month",
        rate: "10",
        // priceId: "price_1NPy4XEIVqRzBbsHcfJNwTxJ",
        // prd
        priceId: "price_1Pp6iVEIVqRzBbsHB3DPRVZR",
        type: "month",
    },
    {
        id: 13,
        product: "First responders rate per year",
        rate: "75",
        // priceId: "price_1NPy4uEIVqRzBbsHsA6xdwYl",
        // prd
        priceId: "price_1Pp6fKEIVqRzBbsHnxkysLNi",
        type: "one",
    },
    {
        id: 14,
        product: "First responders rate per month",
        rate: "10",
        // priceId: "price_1NPy4XEIVqRzBbsHcfJNwTxJ",
        // prd
        priceId: "price_1Pp6iVEIVqRzBbsHB3DPRVZR",
        type: "month",
    },
    {
        id: 15,
        product: "Inner circle members per year",
        rate: "100",
        // priceId: "price_1NPy3kEIVqRzBbsHKKQUC6xV",
        // prd
        priceId: "price_1Pp6gIEIVqRzBbsHdgdKlmhE",
        type: "one",
    },
    {
        id: 16,
        product: "Inner circle members per month",
        rate: "10",
        // priceId: "price_1NPy4XEIVqRzBbsHcfJNwTxJ",
        // prd
        priceId: "price_1Pp6iVEIVqRzBbsHB3DPRVZR",
        type: "month",
    },
    {
        id: 17,
        product: "Inner circle members per year",
        rate: "75",
        // priceId: "price_1NPy4uEIVqRzBbsHsA6xdwYl",
        // prd
        priceId: "price_1Pp6fKEIVqRzBbsHnxkysLNi",
        type: "one",
    },
    {
        id: 18,
        product: "Inner circle members per month",
        rate: "10",
        // priceId: "price_1NPy4XEIVqRzBbsHcfJNwTxJ",
        // prd
        price: "price_1Pp6iVEIVqRzBbsHB3DPRVZR",
        type: "month",
    },
    {
        id: 19,
        product: "All other referrals",
        rate: "120",
        // priceId: "price_1NPy2tEIVqRzBbsHN5oQU2pw",
        // prd
        priceId: "price_1Pp6gfEIVqRzBbsHUGRtuqNM",
        type: "one",
    },
    {
        id: 20,
        product: "All other referrals",
        rate: "75",
        // priceId: "price_1NPy4uEIVqRzBbsHsA6xdwYl",
        // prd
        priceId: "price_1Pp6fKEIVqRzBbsHnxkysLNi",
        type: "one",
    },
    {
        id: 21,
        product: "License code",
        // Aidan: I'm not sure if this object needs a priceId since it's handled in the handlePromotionCodeSubmit function
        rate: "0",
        type: "lifetime",
    },
    {
        id: 22,
        product: "3-Year subscription",
        rate: "300",
        // priceId: "price_1PlZEXEIVqRzBbsH9smfJBFN",
        // prd
        priceId: "price_1PlZEXEIVqRzBbsH9smfJBFN",
        type: "three",
    }
];
