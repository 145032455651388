export const memberships = [
    {
        full_member: [
            {
                year: {
                    key: 1,
                    value: "125",
                    rate: "Annual Plan",
                },
            },
            {
                year: {
                    key: 3,
                    value: "69",
                    rate: "Champions Plan",
                },
            },
        ],
    },
    {
        discount_special: [
            { key: 5, rate: "5 years", value: "250" },
            { key: 6, rate: "Lifetime Plan", value: "699" },
            {
                year: {
                    key: 7,
                    value: "100",
                    rate: "Veterans Special Discount rate per year",
                },
            },
            {
                year: {
                    key: 9,
                    value: "75",
                    rate: "Veterans Special Discount rate per year",
                },
            },
            { key: 22, rate: "3-Year Plan", value: "300" },
        ],
    },
    {
        discount_per: [
            {
                year: {
                    key: 11,
                    value: "100",
                    rate: "First responders rate per year",
                },
            },
            {
                year: {
                    key: 13,
                    value: "75",
                    rate: "First responders rate per year",
                },
            },
        ],
    },
    {
        referrals: [
            {
                year: {
                    key: 15,
                    value: "100",
                    rate: "Inner circle members per year",
                },
            },
            {
                year: {
                    key: 17,
                    value: "75",
                    rate: "Inner circle member per year",
                },
            },
            { year: { key: 19, value: "120", rate: "All other referrals" } },
            { year: { key: 20, value: "75", rate: "All other referrals" } },
        ],
    },
];