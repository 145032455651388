import AWS from "aws-sdk";

import { pdf } from "@react-pdf/renderer";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { restPost } from "../../../store/api";
import { Toastr } from "../../common/toastr";

import PDFLayout from "./PDFLayout";
import GreenBtn from "../../buttons/greenBtn";

const S3_BUCKET = "mybanyan-bucket-staging";
const REGION = "us-west-1";

AWS.config.update({
    accessKeyId: "AKIAQGLUQCEBTIRQDN5J",
    secretAccessKey: "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",
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const getKey = (userId, topicName, topicId, fileName, currentTime) => {
    return userId + "/" + topicName + "/" + topicId + "/" + fileName + ".pdf";
};

export default function OneRecordPDFReport({
    fileName,
    record,
    title,
    name,
    email,
    linkName,
    files,
}) {
    const location = useLocation();
    const user = useSelector((state) => state.auth.user);
    const isSubscriptionDisabled = user && user.type === "readonly";
    const [isLoading, setIsLoading] = useState(false);

    const pathname = location.pathname.split("/");

    const failCallback = (err) => {
        console.error(err);
    };

    const addToDatabase = async (currentTime) => {
        const newFileName = `${fileName
            .split("_")
            .slice(0, -1)
            .join("_")}_${currentTime}`;
        const name = `${fileName
            .split("_")
            .slice(0, -1)
            .join("_")}_${currentTime}_${fileName.split("_").pop()}`;
        const reqBody = {
            topic: pathname[pathname.length - 2],
            id: record._id,
            fileName: name,
            type: "report",
            url: `https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/${getKey(
                user.uid,
                "report",
                record._id,
                newFileName,
                currentTime
            )}`,
        };

        restPost("/fileUploads/new", reqBody, () => {}, failCallback);
        Toastr({
            type: "success",
            message: "Report has been saved in REPORTS",
        });
    };

    const handleDownload = async () => {
        setIsLoading(true);
        for (let i = 0; i < files.length; i++) {
            record[`file ${i + 1}`] = files[i].url;
        }

        const pdfLayout = (
            <PDFLayout
                record={record}
                title={title}
                name={name}
                email={email}
            />
        );

        try {
            const currentTime = new Date().getTime();
            const blob = await pdf(pdfLayout).toBlob();

            const newFileName = `${fileName
                .split("_")
                .slice(0, -1)
                .join("_")}_${currentTime}`;
            const params = {
                ACL: "public-read",
                Key: getKey(
                    user.uid,
                    "report",
                    record._id,
                    newFileName,
                    currentTime
                ),
                Body: blob,
                Bucket: S3_BUCKET,
                ContentType: "application/pdf",
            };

            await myBucket.upload(params).promise();
            await addToDatabase(currentTime);
        } catch (error) {
            console.error("Error uploading PDF to S3: ", error);
        }

        setIsLoading(false);
    };

    return (
        <>
            <GreenBtn
                disabled={isSubscriptionDisabled || isLoading}
                onClick={async () => {
                    handleDownload();
                }}
            >
                Save report
            </GreenBtn>
        </>
    );
}

